import { Fragment, useEffect, useState } from 'react';
import Globe from 'react-globe.gl';
import mapLabels from '../../utils/mapLabels.json';
import createMapArcs from '../../utils/createMapArcs';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
}

const WorldMap = () => {

    // Set state
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    // Handle window resizing and store dimensions in state
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Deconstruct window dimensions and set responsive dimensions for map
    let width = windowDimensions.width;
    let mapWidth = width > 1400 ? 1150 :
        1200 <= width && width < 1400 ? 1000 :
            992 <= width && width < 1200 ? 900 :
                768 <= width && width < 992 ? 700 :
                    width
    let mapHeight = parseInt(mapWidth / 100 * 55.2);

    const places = mapLabels.features
    const arcsData = createMapArcs();

    return (
        <Fragment>
            <h1 className='display-1 welcome-text text-black text-center front mb-30'>From all over the globe</h1>
            {/* <svg width="100%" id="svg" viewBox="0 0 1440 400" xmlns="http://www.w3.org/2000/svg" className="transition duration-300 ease-in-out delay-150"><path d="M 0,400 C 0,400 0,200 0,200 C 42.59837690660605,239.56713103703507 85.1967538132121,279.13426207407014 143,274 C 200.8032461867879,268.86573792592986 273.8113616537577,219.03008274075447 314,195 C 354.1886383462423,170.96991725924553 361.55779957175713,172.74540696291206 406,159 C 450.44220042824287,145.25459303708794 531.9574400592139,115.98828940759736 582,131 C 632.0425599407861,146.01171059240264 650.6124401913876,205.30143540669854 700,225 C 749.3875598086124,244.69856459330146 829.592799175236,224.80596896560843 890,211 C 950.407200824764,197.19403103439157 991.0163631076687,189.47468873086785 1032,193 C 1072.9836368923313,196.52531126913215 1114.3417483940893,211.29527611092018 1155,201 C 1195.6582516059107,190.70472388907982 1235.6166433159744,155.34420682545138 1283,151 C 1330.3833566840256,146.65579317454862 1385.191678342013,173.32789658727432 1440,200 C 1440,200 1440,400 1440,400 Z" stroke="none" strokeWidth="0" fill="#000000ff" className="transition-all duration-300 ease-in-out delay-150 path-0"></path></svg> */}
            <div id="globeViz">
                <Globe
                    globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
                    backgroundColor={"#000000"}

                    // Add place labels
                    labelsData={places}
                    labelLat={d => d.properties.latitude}
                    labelLng={d => d.properties.longitude}
                    labelText={d => d.properties.name}
                    labelSize={0.5}
                    labelDotRadius={0.5}
                    labelColor={() => '#ffffff'}
                    labelResolution={2}

                    // Add arcs
                    arcsData={arcsData}
                    arcColor={'color'}
                    arcDashLength={1}
                    arcDashGap={1}
                    arcDashAnimateTime={() => Math.random() * 4000 + 1000}

                    // Dimensions
                    width={mapWidth}
                    height={mapHeight}
                />
            </div>
            {/* <svg width="100%" id="svg" viewBox="0 0 1440 400" xmlns="http://www.w3.org/2000/svg" className="transition duration-300 ease-in-out delay-150"><path d="M 0,400 C 0,400 0,200 0,200 C 57.16524359618282,207.49758122075656 114.33048719236564,214.99516244151314 158,213 C 201.66951280763436,211.00483755848686 231.84329482672024,199.51693145470406 270,187 C 308.15670517327976,174.48306854529594 354.2963335007534,160.93711173967063 414,152 C 473.7036664992466,143.06288826032937 546.9713711702661,138.73462158661343 595,154 C 643.0286288297339,169.26537841338657 665.8181818181819,204.1244019138756 714,193 C 762.1818181818181,181.8755980861244 835.7559015570063,124.76777075788414 889,140 C 942.2440984429937,155.23222924211586 975.1582119537923,242.80451505458774 1010,252 C 1044.8417880462077,261.19548494541226 1081.611250627825,192.01416902376485 1127,172 C 1172.388749372175,151.98583097623515 1226.3967855349072,181.13880885035292 1280,194 C 1333.6032144650928,206.86119114964708 1386.8016072325463,203.43059557482354 1440,200 C 1440,200 1440,400 1440,400 Z" stroke="none" strokeWidth="0" fill="#000000ff" className="transition-all duration-300 ease-in-out delay-150 path-0" transform="rotate(-180 720 200)"></path></svg> */}
        </Fragment>

    )
}

export default WorldMap

