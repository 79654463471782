import { CONTACT_US_FORM_SUCCESS, CONTACT_US_FORM_ERROR } from './types';
import axios from 'axios';
import { setAlert } from './alert';

// Contact us
export const contactUs = (email, header, body) => async dispatch => {

    try {

        const config = { headers: { 'Content-Type': 'application/json' } }
        const payload = { email, header, body };
        await axios.post(`api/contact/`, payload, config);

        dispatch({
            type: CONTACT_US_FORM_SUCCESS,
            payload: null
        });

        dispatch(setAlert("Thanks for getting in touch! We'll respond as soon as we can.", 'success'));

    } catch (err) {

        dispatch(setAlert('There was a problem with your request. Please try again!', 'danger'));
        dispatch({
            type: CONTACT_US_FORM_ERROR,
            payload: err
        })
    }
}