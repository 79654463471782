import React from 'react'
import { ReactComponent as Flask } from '../../svg/flask.svg'
import { ReactComponent as Django } from '../../svg/django.svg'
import { ReactComponent as OpenCV } from '../../svg/opencv.svg'
import { ReactComponent as OpenAI } from '../../svg/openai.svg'
import { ReactComponent as Python } from '../../svg/python.svg'
import { ReactComponent as ReactIcon } from '../../svg/react.svg'
import { ReactComponent as Tensorflow } from '../../svg/tensorflow.svg'
import { ReactComponent as Unity } from '../../svg/unity.svg'
import { ReactComponent as MongoDB } from '../../svg/mongodb.svg'
import { ReactComponent as Scikit } from '../../svg/scikit-learn.svg'
import { ReactComponent as Numpy } from '../../svg/numpy.svg'
import { ReactComponent as Gcp } from '../../svg/gcp.svg'
import { ReactComponent as Aws } from '../../svg/aws.svg'
import { ReactComponent as Dask } from '../../svg/dask.svg'
import { ReactComponent as Spacy } from '../../svg/spacy.svg'
import { ReactComponent as Neo4j } from '../../svg/neo4j.svg'
import { ReactComponent as Postgres } from '../../svg/postgresql.svg'


const TechIcon = ({ logo }) => {

    const svg = logo === 'django' ? (
        <Django/>
    ) : logo === "flask" ? (
        <Flask/>
    ) : logo === "python" ? (
        <Python/>
    ) : logo === "opencv" ? (
        <OpenCV/>
    ) : logo === "openai" ? (
        <OpenAI/>
    ) : logo === "tensorflow" ? (
        <Tensorflow/>
    ) : logo === "react" ? (
        <ReactIcon/>
    ) : logo === "unity" ? (
        <Unity/>
    ) : logo === "mongodb" ? (
        <MongoDB/>
    ) : logo === "scikit-learn" ? (
        <Scikit/>
    ) : logo === "numpy" ? (
        <Numpy/>
    ) : logo === "gcp" ? (
        <Gcp/>
    ) : logo === "aws" ? (
        <Aws/>
    ) : logo === "dask" ? (
        <Dask/>
    ) : logo === "spacy" ? (
        <Spacy/>
    ) : logo === "neo4j" ? (
        <Neo4j/>
    ) : logo === "postgres" ? (
        <Postgres/>
    ) : <span></span>
    
    return (
        <span className="svg-icon svg-icon-3x">
            {svg}
        </span>
    )
}

export default TechIcon
