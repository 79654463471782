import projects from '../data/projects';

function formatProjectsLong() {

    let formattedProjects = []
    for (let i = 0; i < projects.length; i++) {
        let project = projects[i];
        let align;
        if (i % 2 === 0) {
            align = "left"
        } else {
            align = "right"
        }
        let content = {
            'id': project['id'],
            'alignment': align,
            'name': project['name'],
            'header': project['header'],
            'location': project['client_location'],
            'industry': project['client_industry'],
            'long_description': project['long_description'],
            'colour_theme': project['colour_theme'],
            'colour': project['colour'],
            'tech_areas': project['tech_areas'],
            'tech_stack': project['tech_stack'],
            'key_points': project['key_points'],
            'main_image': project['main_image'],
            'secondary_images': project['secondary_images'],
            'impact': project['impact']
        };
        formattedProjects.push(content);
    }

    return formattedProjects;
};

export default formatProjectsLong;