import React, { Fragment } from 'react'
import 'animate.css';
import Spacer from '../layout/Spacer';

const Intro = () => {
    return (
        <Fragment>
            <svg width="100%" id="svg" viewBox="0 0 1440 400" xmlns="http://www.w3.org/2000/svg" className="transition duration-300 ease-in-out delay-150 mt-20"><path d="M 0,400 C 0,400 0,200 0,200 C 52.6182294007243,163.65336117793228 105.2364588014486,127.30672235586457 153,141 C 200.7635411985514,154.69327764413543 243.67239419492984,218.4264717544741 292,237 C 340.32760580507016,255.5735282455259 394.07396441883213,228.98739062623912 441,197 C 487.92603558116787,165.01260937376088 528.0317481297416,127.62396574056942 579,138 C 629.9682518702584,148.37603425943058 691.799043062201,206.51674641148324 739,223 C 786.200956937799,239.48325358851676 818.7720796214544,214.3090486134976 862,217 C 905.2279203785456,219.6909513865024 959.1126384519814,250.24705913452644 1007,235 C 1054.8873615480186,219.75294086547356 1096.7773665706202,158.70271484839674 1147,140 C 1197.2226334293798,121.29728515160328 1255.7778952655372,144.94208147188664 1306,162 C 1356.2221047344628,179.05791852811336 1398.1110523672314,189.52895926405668 1440,200 C 1440,200 1440,400 1440,400 Z" stroke="none" strokeWidth="0" fill="#3c89d1ff" className="transition-all duration-300 ease-in-out delay-150 path-0"></path></svg>
            <div className="vw-100 bg-primary">
                <div className="container p-20">

                    {/* Advisory */}
                    <div className="row justify-content-start">
                        <span className="svg-icon svg-icon-white svg-icon-10x p-0">
                            <svg className="animate__animated animate__tada animate__slow animate__infinite" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <rect x="0" y="0" width="24" height="24" />
                                    <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M14.4862 18L12.7975 21.0566C12.5304 21.54 11.922 21.7153 11.4386 21.4483C11.2977 21.3704 11.1777 21.2597 11.0887 21.1255L9.01653 18H5C3.34315 18 2 16.6569 2 15V6C2 4.34315 3.34315 3 5 3H19C20.6569 3 22 4.34315 22 6V15C22 16.6569 20.6569 18 19 18H14.4862Z" fill="black" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M6 7H15C15.5523 7 16 7.44772 16 8C16 8.55228 15.5523 9 15 9H6C5.44772 9 5 8.55228 5 8C5 7.44772 5.44772 7 6 7ZM6 11H11C11.5523 11 12 11.4477 12 12C12 12.5523 11.5523 13 11 13H6C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11Z" fill="black" />
                                </g>
                            </svg>
                        </span>
                        <h1 className="display-2 welcome-text text-white front">Advisory</h1>
                        <p className="text-white front">
                            We have years of experience within technology, from building start-up companies, to scaling global operations or even rapidly developing solutions to test the market. Our team of experts would be happy to provide advisory consulting services within the following areas:
                        </p>
                        <div className="row text-left mt-5">
                            <div className="col mb-10">
                                <div className="card hover-icon-primary border-0 rounded-xxl p-5 text-black text-center jump">
                                    <span className="svg-icon svg-icon-dark svg-icon-4x p-3">
                                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect x="0" y="0" width="24" height="24" />
                                                <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                                <path d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z" fill="#000000" fillRule="nonzero" />
                                            </g>
                                        </svg>
                                    </span>
                                    <p>Technology best practices</p>
                                </div>
                            </div>
                            <div className="col mb-10">
                                <div className="card hover-icon-primary border-0 rounded-xxl p-5 text-black text-center jump">
                                    <span className="svg-icon svg-icon-dark svg-icon-4x p-3">
                                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect x="0" y="0" width="24" height="24" />
                                                <path d="M13.5,21 L13.5,18 C13.5,17.4477153 13.0522847,17 12.5,17 L11.5,17 C10.9477153,17 10.5,17.4477153 10.5,18 L10.5,21 L5,21 L5,4 C5,2.8954305 5.8954305,2 7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,21 L13.5,21 Z M9,4 C8.44771525,4 8,4.44771525 8,5 L8,6 C8,6.55228475 8.44771525,7 9,7 L10,7 C10.5522847,7 11,6.55228475 11,6 L11,5 C11,4.44771525 10.5522847,4 10,4 L9,4 Z M14,4 C13.4477153,4 13,4.44771525 13,5 L13,6 C13,6.55228475 13.4477153,7 14,7 L15,7 C15.5522847,7 16,6.55228475 16,6 L16,5 C16,4.44771525 15.5522847,4 15,4 L14,4 Z M9,8 C8.44771525,8 8,8.44771525 8,9 L8,10 C8,10.5522847 8.44771525,11 9,11 L10,11 C10.5522847,11 11,10.5522847 11,10 L11,9 C11,8.44771525 10.5522847,8 10,8 L9,8 Z M9,12 C8.44771525,12 8,12.4477153 8,13 L8,14 C8,14.5522847 8.44771525,15 9,15 L10,15 C10.5522847,15 11,14.5522847 11,14 L11,13 C11,12.4477153 10.5522847,12 10,12 L9,12 Z M14,12 C13.4477153,12 13,12.4477153 13,13 L13,14 C13,14.5522847 13.4477153,15 14,15 L15,15 C15.5522847,15 16,14.5522847 16,14 L16,13 C16,12.4477153 15.5522847,12 15,12 L14,12 Z" fill="#000000" />
                                                <rect fill="#FFFFFF" x="13" y="8" width="3" height="3" rx="1" />
                                                <path d="M4,21 L20,21 C20.5522847,21 21,21.4477153 21,22 L21,22.4 C21,22.7313708 20.7313708,23 20.4,23 L3.6,23 C3.26862915,23 3,22.7313708 3,22.4 L3,22 C3,21.4477153 3.44771525,21 4,21 Z" fill="#000000" opacity="0.3" />
                                            </g>
                                        </svg>
                                    </span>
                                    <p>Architecture design</p>
                                </div>
                            </div>
                            <div className="col mb-10">
                                <div className="card hover-icon-primary border-0 rounded-xxl p-5 text-black text-center jump">
                                    <span className="svg-icon svg-icon-dark svg-icon-4x p-3">
                                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect x="0" y="0" width="24" height="24" />
                                                <path d="M19,11 L21,11 C21.5522847,11 22,11.4477153 22,12 C22,12.5522847 21.5522847,13 21,13 L19,13 C18.4477153,13 18,12.5522847 18,12 C18,11.4477153 18.4477153,11 19,11 Z M3,11 L5,11 C5.55228475,11 6,11.4477153 6,12 C6,12.5522847 5.55228475,13 5,13 L3,13 C2.44771525,13 2,12.5522847 2,12 C2,11.4477153 2.44771525,11 3,11 Z M12,2 C12.5522847,2 13,2.44771525 13,3 L13,5 C13,5.55228475 12.5522847,6 12,6 C11.4477153,6 11,5.55228475 11,5 L11,3 C11,2.44771525 11.4477153,2 12,2 Z M12,18 C12.5522847,18 13,18.4477153 13,19 L13,21 C13,21.5522847 12.5522847,22 12,22 C11.4477153,22 11,21.5522847 11,21 L11,19 C11,18.4477153 11.4477153,18 12,18 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                                <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="2" />
                                                <path d="M12,17 C14.7614237,17 17,14.7614237 17,12 C17,9.23857625 14.7614237,7 12,7 C9.23857625,7 7,9.23857625 7,12 C7,14.7614237 9.23857625,17 12,17 Z M12,19 C8.13400675,19 5,15.8659932 5,12 C5,8.13400675 8.13400675,5 12,5 C15.8659932,5 19,8.13400675 19,12 C19,15.8659932 15.8659932,19 12,19 Z" fill="#000000" fillRule="nonzero" />
                                            </g>
                                        </svg>
                                    </span>
                                    <p>Minimum Viable Prototyping</p>
                                </div>
                            </div>
                            <div className="col mb-10">
                                <div className="card hover-icon-primary border-0 rounded-xxl p-5 text-black text-center jump">
                                    <span className="svg-icon svg-icon-dark svg-icon-4x p-3">
                                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect x="0" y="0" width="24" height="24" />
                                                <path d="M21,5.5 L21,17.5 C21,18.3284271 20.3284271,19 19.5,19 L4.5,19 C3.67157288,19 3,18.3284271 3,17.5 L3,14.5 C3,13.6715729 3.67157288,13 4.5,13 L9,13 L9,9.5 C9,8.67157288 9.67157288,8 10.5,8 L15,8 L15,5.5 C15,4.67157288 15.6715729,4 16.5,4 L19.5,4 C20.3284271,4 21,4.67157288 21,5.5 Z" fill="#000000" fillRule="nonzero" />
                                            </g>
                                        </svg>
                                    </span>
                                    <p>Managing scale</p>
                                </div>
                            </div>
                            <div className="col mb-10">
                                <div className="card hover-icon-primary border-0 rounded-xxl p-5 text-black text-center jump">
                                    <span className="svg-icon svg-icon-dark svg-icon-4x p-3">
                                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect x="0" y="0" width="24" height="24" />
                                                <circle fill="#000000" opacity="0.3" cx="12" cy="9" r="8" />
                                                <path d="M14.5297296,11 L9.46184488,11 L11.9758349,17.4645458 L14.5297296,11 Z M10.5679953,19.3624463 L6.53815512,9 L17.4702704,9 L13.3744964,19.3674279 L11.9759405,18.814912 L10.5679953,19.3624463 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                                <path d="M10,22 L14,22 L14,22 C14,23.1045695 13.1045695,24 12,24 L12,24 C10.8954305,24 10,23.1045695 10,22 Z" fill="#000000" opacity="0.3" />
                                                <path d="M9,20 C8.44771525,20 8,19.5522847 8,19 C8,18.4477153 8.44771525,18 9,18 C8.44771525,18 8,17.5522847 8,17 C8,16.4477153 8.44771525,16 9,16 L15,16 C15.5522847,16 16,16.4477153 16,17 C16,17.5522847 15.5522847,18 15,18 C15.5522847,18 16,18.4477153 16,19 C16,19.5522847 15.5522847,20 15,20 C15.5522847,20 16,20.4477153 16,21 C16,21.5522847 15.5522847,22 15,22 L9,22 C8.44771525,22 8,21.5522847 8,21 C8,20.4477153 8.44771525,20 9,20 Z" fill="#000000" />
                                            </g>
                                        </svg>
                                    </span>
                                    <p>Concept ideation</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Spacer height="30" />

                    {/* Build */}
                    <div className="row justify-content-end text-right my-30">
                        <span className="svg-icon svg-icon-white svg-icon-10x p-0">
                            <svg className="animate__animated animate__headShake animate__slow animate__infinite" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <rect x="0" y="0" width="24" height="24" />
                                    <path d="M15.9497475,3.80761184 L13.0246125,6.73274681 C12.2435639,7.51379539 12.2435639,8.78012535 13.0246125,9.56117394 L14.4388261,10.9753875 C15.2198746,11.7564361 16.4862046,11.7564361 17.2672532,10.9753875 L20.1923882,8.05025253 C20.7341101,10.0447871 20.2295941,12.2556873 18.674559,13.8107223 C16.8453326,15.6399488 14.1085592,16.0155296 11.8839934,14.9444337 L6.75735931,20.0710678 C5.97631073,20.8521164 4.70998077,20.8521164 3.92893219,20.0710678 C3.1478836,19.2900192 3.1478836,18.0236893 3.92893219,17.2426407 L9.05556629,12.1160066 C7.98447038,9.89144078 8.36005124,7.15466739 10.1892777,5.32544095 C11.7443127,3.77040588 13.9552129,3.26588995 15.9497475,3.80761184 Z" fill="#000000" />
                                    <path d="M16.6568542,5.92893219 L18.0710678,7.34314575 C18.4615921,7.73367004 18.4615921,8.36683502 18.0710678,8.75735931 L16.6913928,10.1370344 C16.3008685,10.5275587 15.6677035,10.5275587 15.2771792,10.1370344 L13.8629656,8.7228208 C13.4724413,8.33229651 13.4724413,7.69913153 13.8629656,7.30860724 L15.2426407,5.92893219 C15.633165,5.5384079 16.26633,5.5384079 16.6568542,5.92893219 Z" fill="#000000" opacity="0.3" />
                                </g>
                            </svg>
                        </span>
                        <h1 className="display-2 welcome-text text-white front">Build</h1>
                        <p className="text-white front">
                            Making the leap from idea to reality is a daunting step, especially when trying to find a trusted partner. Our wide variety of experience with businesses of all sizes and use-cases, as well as our fundamentally collaborative and creative mindset sets up apart. Some examples of what our vast technical expertise can help you to build includes:
                        </p>
                        <div className="row mt-5">
                            <div className="col mb-10">
                                <div className="card hover-icon-primary border-0 rounded-xxl p-5 text-black text-center jump">
                                    <span className="svg-icon svg-icon-dark svg-icon-4x p-3">
                                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect x="0" y="0" width="24" height="24" />
                                                <path d="M19,11 L21,11 C21.5522847,11 22,11.4477153 22,12 C22,12.5522847 21.5522847,13 21,13 L19,13 C18.4477153,13 18,12.5522847 18,12 C18,11.4477153 18.4477153,11 19,11 Z M3,11 L5,11 C5.55228475,11 6,11.4477153 6,12 C6,12.5522847 5.55228475,13 5,13 L3,13 C2.44771525,13 2,12.5522847 2,12 C2,11.4477153 2.44771525,11 3,11 Z M12,2 C12.5522847,2 13,2.44771525 13,3 L13,5 C13,5.55228475 12.5522847,6 12,6 C11.4477153,6 11,5.55228475 11,5 L11,3 C11,2.44771525 11.4477153,2 12,2 Z M12,18 C12.5522847,18 13,18.4477153 13,19 L13,21 C13,21.5522847 12.5522847,22 12,22 C11.4477153,22 11,21.5522847 11,21 L11,19 C11,18.4477153 11.4477153,18 12,18 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                                <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="2" />
                                                <path d="M12,17 C14.7614237,17 17,14.7614237 17,12 C17,9.23857625 14.7614237,7 12,7 C9.23857625,7 7,9.23857625 7,12 C7,14.7614237 9.23857625,17 12,17 Z M12,19 C8.13400675,19 5,15.8659932 5,12 C5,8.13400675 8.13400675,5 12,5 C15.8659932,5 19,8.13400675 19,12 C19,15.8659932 15.8659932,19 12,19 Z" fill="#000000" fillRule="nonzero" />
                                            </g>
                                        </svg>
                                    </span>
                                    <p>Minimum Viable Prototypes</p>
                                </div>
                            </div>
                            <div className="col mb-10">
                                <div className="card hover-icon-primary border-0 rounded-xxl p-5 text-black text-center jump">
                                    <span className="svg-icon svg-icon-dark svg-icon-4x p-3">
                                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect x="0" y="0" width="24" height="24" />
                                                <path d="M12,3 C16.418278,3 20,6.581722 20,11 L20,21 C20,21.5522847 19.5522847,22 19,22 L5,22 C4.44771525,22 4,21.5522847 4,21 L4,11 C4,6.581722 7.581722,3 12,3 Z M9,10 C7.34314575,10 6,11.3431458 6,13 C6,14.6568542 7.34314575,16 9,16 L15,16 C16.6568542,16 18,14.6568542 18,13 C18,11.3431458 16.6568542,10 15,10 L9,10 Z" fill="#000000" />
                                                <path d="M15,14 C14.4477153,14 14,13.5522847 14,13 C14,12.4477153 14.4477153,12 15,12 C15.5522847,12 16,12.4477153 16,13 C16,13.5522847 15.5522847,14 15,14 Z M9,14 C8.44771525,14 8,13.5522847 8,13 C8,12.4477153 8.44771525,12 9,12 C9.55228475,12 10,12.4477153 10,13 C10,13.5522847 9.55228475,14 9,14 Z" fill="#000000" opacity="0.3" />
                                            </g>
                                        </svg>
                                    </span>
                                    <p>Robotic Process Automation bots</p>
                                </div>
                            </div>
                            <div className="col mb-10">
                                <div className="card hover-icon-primary border-0 rounded-xxl p-5 text-black text-center jump">
                                    <span className="svg-icon svg-icon-dark svg-icon-4x p-3">
                                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect x="0" y="0" width="24" height="24" />
                                                <path d="M8.79188247,3.31801948 L19.5720786,3.31801948 C19.8482209,3.31801948 20.0720786,3.54187711 20.0720786,3.81801948 C20.0720786,3.85094548 20.0688262,3.88379096 20.0623689,3.91607755 L18.4879986,11.7879291 C18.0774792,13.8405261 16.275227,15.3180195 14.1819805,15.3180195 L14.1819805,15.3180195 C12.088734,15.3180195 10.2864818,13.8405261 9.87596243,11.7879291 L8.30159213,3.91607755 C8.24743615,3.64529768 8.42304452,3.38188512 8.6938244,3.32772915 C8.72611099,3.32127183 8.75895647,3.31801948 8.79188247,3.31801948 Z" fill="#000000" opacity="0.3" transform="translate(14.181981, 9.318019) rotate(-315.000000) translate(-14.181981, -9.318019) " />
                                                <path d="M4.96174059,13.0963957 L7.84544682,13.0963957 L9.2623331,17.0271325 C9.83144628,18.6059707 9.01290245,20.3472293 7.43406428,20.9163425 C7.10356453,21.0354755 6.75490946,21.0963957 6.4035937,21.0963957 C4.72531489,21.0963957 3.36480109,19.7358819 3.36480109,18.0576031 C3.36480109,17.7062873 3.42572129,17.3576322 3.54485431,17.0271325 L4.96174059,13.0963957 Z M6.4035937,19.6678242 C7.19990719,19.6678242 7.84544682,19.028231 7.84544682,18.2392528 C7.84544682,17.4502746 7.19990719,16.8106814 6.4035937,16.8106814 C5.60728022,16.8106814 4.96174059,17.4502746 4.96174059,18.2392528 C4.96174059,19.028231 5.60728022,19.6678242 6.4035937,19.6678242 Z" fill="#000000" transform="translate(6.404008, 17.096396) rotate(-315.000000) translate(-6.404008, -17.096396) " />
                                            </g>
                                        </svg>
                                    </span>
                                    <p>Web scrapers & crawlers</p>
                                </div>
                            </div>
                            <div className="col mb-10">
                                <div className="card hover-icon-primary border-0 rounded-xxl p-5 text-black text-center jump">
                                    <span className="svg-icon svg-icon-dark svg-icon-4x p-3">
                                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect x="0" y="0" width="24" height="24" />
                                                <path d="M11,20 L11,17 C11,16.4477153 11.4477153,16 12,16 C12.5522847,16 13,16.4477153 13,17 L13,20 L15.5,20 C15.7761424,20 16,20.2238576 16,20.5 C16,20.7761424 15.7761424,21 15.5,21 L8.5,21 C8.22385763,21 8,20.7761424 8,20.5 C8,20.2238576 8.22385763,20 8.5,20 L11,20 Z" fill="#000000" opacity="0.3" />
                                                <path d="M3,5 L21,5 C21.5522847,5 22,5.44771525 22,6 L22,16 C22,16.5522847 21.5522847,17 21,17 L3,17 C2.44771525,17 2,16.5522847 2,16 L2,6 C2,5.44771525 2.44771525,5 3,5 Z M4.5,8 C4.22385763,8 4,8.22385763 4,8.5 C4,8.77614237 4.22385763,9 4.5,9 L13.5,9 C13.7761424,9 14,8.77614237 14,8.5 C14,8.22385763 13.7761424,8 13.5,8 L4.5,8 Z M4.5,10 C4.22385763,10 4,10.2238576 4,10.5 C4,10.7761424 4.22385763,11 4.5,11 L7.5,11 C7.77614237,11 8,10.7761424 8,10.5 C8,10.2238576 7.77614237,10 7.5,10 L4.5,10 Z" fill="#000000" />
                                            </g>
                                        </svg>
                                    </span>
                                    <p>Full-stack web applications</p>
                                </div>
                            </div>
                            <div className="col mb-10">
                                <div className="card hover-icon-primary border-0 rounded-xxl p-5 text-black text-center jump">
                                    <span className="svg-icon svg-icon-dark svg-icon-4x p-3">
                                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect x="0" y="0" width="24" height="24" />
                                                <rect fill="#000000" opacity="0.3" x="4" y="4" width="16" height="16" rx="2" />
                                                <rect fill="#000000" opacity="0.3" x="9" y="9" width="6" height="6" />
                                                <path d="M20,7 L21,7 C21.5522847,7 22,7.44771525 22,8 L22,8 C22,8.55228475 21.5522847,9 21,9 L20,9 L20,7 Z" fill="#000000" />
                                                <path d="M20,11 L21,11 C21.5522847,11 22,11.4477153 22,12 L22,12 C22,12.5522847 21.5522847,13 21,13 L20,13 L20,11 Z" fill="#000000" />
                                                <path d="M20,15 L21,15 C21.5522847,15 22,15.4477153 22,16 L22,16 C22,16.5522847 21.5522847,17 21,17 L20,17 L20,15 Z" fill="#000000" />
                                                <path d="M3,7 L4,7 L4,9 L3,9 C2.44771525,9 2,8.55228475 2,8 L2,8 C2,7.44771525 2.44771525,7 3,7 Z" fill="#000000" />
                                                <path d="M3,11 L4,11 L4,13 L3,13 C2.44771525,13 2,12.5522847 2,12 L2,12 C2,11.4477153 2.44771525,11 3,11 Z" fill="#000000" />
                                                <path d="M3,15 L4,15 L4,17 L3,17 C2.44771525,17 2,16.5522847 2,16 L2,16 C2,15.4477153 2.44771525,15 3,15 Z" fill="#000000" />
                                            </g>
                                        </svg>
                                    </span>
                                    <p>Custom artificial intelligence algorithms</p>
                                </div>
                            </div>
                            <div className="col mb-10">
                                <div className="card hover-icon-primary border-0 rounded-xxl p-5 text-black text-center jump">
                                    <span className="svg-icon svg-icon-dark svg-icon-4x p-3">
                                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect x="0" y="0" width="24" height="24" />
                                                <path d="M12.4644661,14.5355339 L9.46446609,14.5355339 C8.91218134,14.5355339 8.46446609,14.9832492 8.46446609,15.5355339 C8.46446609,16.0878187 8.91218134,16.5355339 9.46446609,16.5355339 L12.4644661,16.5355339 L12.4644661,17.5355339 C12.4644661,18.6401034 11.5690356,19.5355339 10.4644661,19.5355339 L6.46446609,19.5355339 C5.35989659,19.5355339 4.46446609,18.6401034 4.46446609,17.5355339 L4.46446609,13.5355339 C4.46446609,12.4309644 5.35989659,11.5355339 6.46446609,11.5355339 L10.4644661,11.5355339 C11.5690356,11.5355339 12.4644661,12.4309644 12.4644661,13.5355339 L12.4644661,14.5355339 Z" fill="#000000" opacity="0.3" transform="translate(8.464466, 15.535534) rotate(-45.000000) translate(-8.464466, -15.535534) " />
                                                <path d="M11.5355339,9.46446609 L14.5355339,9.46446609 C15.0878187,9.46446609 15.5355339,9.01675084 15.5355339,8.46446609 C15.5355339,7.91218134 15.0878187,7.46446609 14.5355339,7.46446609 L11.5355339,7.46446609 L11.5355339,6.46446609 C11.5355339,5.35989659 12.4309644,4.46446609 13.5355339,4.46446609 L17.5355339,4.46446609 C18.6401034,4.46446609 19.5355339,5.35989659 19.5355339,6.46446609 L19.5355339,10.4644661 C19.5355339,11.5690356 18.6401034,12.4644661 17.5355339,12.4644661 L13.5355339,12.4644661 C12.4309644,12.4644661 11.5355339,11.5690356 11.5355339,10.4644661 L11.5355339,9.46446609 Z" fill="#000000" transform="translate(15.535534, 8.464466) rotate(-45.000000) translate(-15.535534, -8.464466) " />
                                            </g>
                                        </svg>
                                    </span>
                                    <p>Decentralized applications</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Spacer height="30" />

                    {/* Lifecycle */}
                    <div className="row justify-content-start">
                        <span className="svg-icon svg-icon-white svg-icon-10x p-0">
                            <svg className="animate__animated animate__bounce animate__slow animate__infinite" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <rect x="0" y="0" width="24" height="24" />
                                    <path d="M10.9630156,7.5 L11.0475062,7.5 C11.3043819,7.5 11.5194647,7.69464724 11.5450248,7.95024814 L12,12.5 L15.2480695,14.3560397 C15.403857,14.4450611 15.5,14.6107328 15.5,14.7901613 L15.5,15 C15.5,15.2109164 15.3290185,15.3818979 15.1181021,15.3818979 C15.0841582,15.3818979 15.0503659,15.3773725 15.0176181,15.3684413 L10.3986612,14.1087258 C10.1672824,14.0456225 10.0132986,13.8271186 10.0316926,13.5879956 L10.4644883,7.96165175 C10.4845267,7.70115317 10.7017474,7.5 10.9630156,7.5 Z" fill="#000000" />
                                    <path d="M7.38979581,2.8349582 C8.65216735,2.29743306 10.0413491,2 11.5,2 C17.2989899,2 22,6.70101013 22,12.5 C22,18.2989899 17.2989899,23 11.5,23 C5.70101013,23 1,18.2989899 1,12.5 C1,11.5151324 1.13559454,10.5619345 1.38913364,9.65805651 L3.31481075,10.1982117 C3.10672013,10.940064 3,11.7119264 3,12.5 C3,17.1944204 6.80557963,21 11.5,21 C16.1944204,21 20,17.1944204 20,12.5 C20,7.80557963 16.1944204,4 11.5,4 C10.54876,4 9.62236069,4.15592757 8.74872191,4.45446326 L9.93948308,5.87355717 C10.0088058,5.95617272 10.0495583,6.05898805 10.05566,6.16666224 C10.0712834,6.4423623 9.86044965,6.67852665 9.5847496,6.69415008 L4.71777931,6.96995273 C4.66931162,6.97269931 4.62070229,6.96837279 4.57348157,6.95710938 C4.30487471,6.89303938 4.13906482,6.62335149 4.20313482,6.35474463 L5.33163823,1.62361064 C5.35654118,1.51920756 5.41437908,1.4255891 5.49660017,1.35659741 C5.7081375,1.17909652 6.0235153,1.2066885 6.2010162,1.41822583 L7.38979581,2.8349582 Z" fill="#000000" opacity="0.3" />
                                </g>
                            </svg>
                        </span>
                        <h1 className="display-2 welcome-text text-white front">Lifecycle</h1>
                        <p className="text-white front">
                            We appreciate that technology must be nurtured, improved and integrated continuously. Therefore we see the integration and upkeep of your solution to be a vital part of our offering. This includes services such as:
                        </p>
                        <div className="row text-left mt-5">
                            <div className="col mb-10">
                                <div className="card hover-icon-primary border-0 rounded-xxl p-5 text-black text-center jump">
                                    <span className="svg-icon svg-icon-dark svg-icon-4x p-3">
                                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect x="0" y="0" width="24" height="24" />
                                                <path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3" />
                                                <path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000" />
                                                <rect fill="#000000" opacity="0.3" x="10" y="9" width="7" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.3" x="7" y="9" width="2" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.3" x="7" y="13" width="2" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.3" x="10" y="13" width="7" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.3" x="7" y="17" width="2" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.3" x="10" y="17" width="7" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                    <p>Technology audit</p>
                                </div>
                            </div>
                            <div className="col mb-10">
                                <div className="card hover-icon-primary border-0 rounded-xxl p-5 text-black text-center jump">
                                    <span className="svg-icon svg-icon-dark svg-icon-4x p-3">
                                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect x="0" y="0" width="24" height="24" />
                                                <path d="M4.5,21 L21.5,21 C22.3284271,21 23,20.3284271 23,19.5 L23,8.5 C23,7.67157288 22.3284271,7 21.5,7 L11,7 L8.43933983,4.43933983 C8.15803526,4.15803526 7.77650439,4 7.37867966,4 L4.5,4 C3.67157288,4 3,4.67157288 3,5.5 L3,19.5 C3,20.3284271 3.67157288,21 4.5,21 Z" fill="#000000" opacity="0.3" />
                                                <path d="M2.5,19 L19.5,19 C20.3284271,19 21,18.3284271 21,17.5 L21,6.5 C21,5.67157288 20.3284271,5 19.5,5 L9,5 L6.43933983,2.43933983 C6.15803526,2.15803526 5.77650439,2 5.37867966,2 L2.5,2 C1.67157288,2 1,2.67157288 1,3.5 L1,17.5 C1,18.3284271 1.67157288,19 2.5,19 Z" fill="#000000" />
                                            </g>
                                        </svg>
                                    </span>
                                    <p>AI model management</p>
                                </div>
                            </div>
                            <div className="col mb-10">
                                <div className="card hover-icon-primary border-0 rounded-xxl p-5 text-black text-center jump">
                                    <span className="svg-icon svg-icon-dark svg-icon-4x p-3">
                                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect x="0" y="0" width="24" height="24" />
                                                <rect fill="#000000" opacity="0.3" x="12" y="4" width="3" height="13" rx="1.5" />
                                                <rect fill="#000000" opacity="0.3" x="7" y="9" width="3" height="8" rx="1.5" />
                                                <path d="M5,19 L20,19 C20.5522847,19 21,19.4477153 21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 C4.55228475,3 5,3.44771525 5,4 L5,19 Z" fill="#000000" fillRule="nonzero" />
                                                <rect fill="#000000" opacity="0.3" x="17" y="11" width="3" height="6" rx="1.5" />
                                            </g>
                                        </svg>
                                    </span>
                                    <p>Statistical data monitoring</p>
                                </div>
                            </div>
                            <div className="col mb-10">
                                <div className="card hover-icon-primary border-0 rounded-xxl p-5 text-black text-center jump">
                                    <span className="svg-icon svg-icon-dark svg-icon-4x p-3">
                                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect x="0" y="0" width="24" height="24" />
                                                <path d="M3.5,21 L20.5,21 C21.3284271,21 22,20.3284271 22,19.5 L22,8.5 C22,7.67157288 21.3284271,7 20.5,7 L10,7 L7.43933983,4.43933983 C7.15803526,4.15803526 6.77650439,4 6.37867966,4 L3.5,4 C2.67157288,4 2,4.67157288 2,5.5 L2,19.5 C2,20.3284271 2.67157288,21 3.5,21 Z" fill="#000000" opacity="0.3" />
                                                <path d="M10.875,16.75 C10.6354167,16.75 10.3958333,16.6541667 10.2041667,16.4625 L8.2875,14.5458333 C7.90416667,14.1625 7.90416667,13.5875 8.2875,13.2041667 C8.67083333,12.8208333 9.29375,12.8208333 9.62916667,13.2041667 L10.875,14.45 L14.0375,11.2875 C14.4208333,10.9041667 14.9958333,10.9041667 15.3791667,11.2875 C15.7625,11.6708333 15.7625,12.2458333 15.3791667,12.6291667 L11.5458333,16.4625 C11.3541667,16.6541667 11.1145833,16.75 10.875,16.75 Z" fill="#000000" />
                                            </g>
                                        </svg>
                                    </span>
                                    <p>Updates & upgrades</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <svg width="100%" id="svg" viewBox="0 0 1440 400" xmlns="http://www.w3.org/2000/svg" className="transition duration-300 ease-in-out delay-150"><path d="M 0,400 C 0,400 0,200 0,200 C 48.52346083692406,228.9733141240847 97.04692167384812,257.9466282481694 151,233 C 204.95307832615188,208.0533717518306 264.3357741415316,129.18680113140712 308,132 C 351.6642258584684,134.81319886859288 379.6099817600254,219.30616722620215 422,238 C 464.3900182399746,256.69383277379785 521.2242988183668,209.5885299637844 578,198 C 634.7757011816332,186.4114700362156 691.4928229665071,210.33971291866027 733,231 C 774.5071770334929,251.66028708133973 800.8044093156045,269.0526183615745 844,271 C 887.1955906843955,272.9473816384255 947.2895397710749,259.4498136350419 996,246 C 1044.710460228925,232.55018636495808 1082.037431600095,219.14812709825793 1136,228 C 1189.962568399905,236.85187290174207 1260.5607338285442,267.95767797192633 1314,267 C 1367.4392661714558,266.04232202807367 1403.7196330857278,233.02116101403684 1440,200 C 1440,200 1440,400 1440,400 Z" stroke="none" strokeWidth="0" fill="#3c89d1ff" className="transition-all duration-300 ease-in-out delay-150 path-0" transform="rotate(-180 720 200)"></path></svg>

        </Fragment>
    )
}

export default Intro
