const projects = [
    {
        'id': 'storyroom-topic-extraction',
        'name': 'Ubiquitous Keyword Extraction',
        'header': 'Creating a data source agnostic extraction algorithm to identify keywords within text',
        'client_name': 'Storyroom',
        'client_location': 'California, USA',
        'client_industry': 'Technology',
        'short_description': 'This project aimed to extract meaningful keyword topics from social, short and long forms of textual data. It involved a custom robust cleansing & processing pipeline as well as a scoring and reinforcement system to evaluate and improve extraction over time.',
        'long_description': 'This project aimed to extract meaningful keyword topics from social, short and long forms of textual data. It involved a custom robust cleansing & processing pipeline as well as a scoring and reinforcement system to evaluate and improve extraction over time.',
        'colour_theme': 'dark-gradient',
        'colour': 'dark',
        'tech_areas': ['Artificial Intelligence', 'Natural Language Processing', 'Reinforcement Learning'],
        'tech_stack': [
            ['python', 'https://www.python.org/'],
            ['spacy', 'https://spacy.io/'],
            ['flask', 'https://flask.palletsprojects.com/en/2.0.x/'],
            ['aws', 'https://aws.amazon.com/']
        ],
        'key_points': [
            'Created an algorithm to extract keyword topics from text, independant of source or length',
            'Developed an algorithm incorporating machine learning, statistical analysis, business rules and a custom scoring system',
            'Deployed the algorithm as a REST API endpoint with swagger documentation'
        ],
        'main_image': 'img/graphics/projects/...',
        'secondary_images': [
            'img/graphics/projects/...',
            'img/graphics/projects/...',
            'img/graphics/projects/...'
        ],
        'impact': [
            'Created highly valuable intellectual property to integrate throughout the business',
            "Enabled the business to perform batch keyword extraction jobs on 100000's of data sources",
            'Developed a feedback endpoint to reinforce good and bad keywords for future extractions',
        ]
    },
    {
        'id': 'ango-product-categorisation',
        'name': 'Sustainable Product Categorisation',
        'header': 'Using computer vision to categorise sustainable products',
        'client_name': 'Ango',
        'client_location': 'Bournemouth, UK',
        'client_industry': 'Sustainability',
        'short_description': 'Ango is a sustainable retail platform that helped users to make better buying decisions and to buy sustainable alternatives when shopping online. We helped Ango to automate the product categorisation process by creating a Deep Learning model to predict the most likely category of a product based on a product image.',
        'long_description': 'Ango is a sustainable retail platform that helped users to make better buying decisions and to buy sustainable alternatives when shopping online. We helped Ango to automate the product categorisation process by creating a Deep Learning model to predict the most likely category of a product based on a product image.',
        'colour_theme': 'success-gradient',
        'colour': 'success',
        'tech_areas': ['Artificial Intelligence', 'Deep Learning', 'Computer Vision'],
        'tech_stack': [
            ['python', 'https://www.python.org/'],
            ['opencv', 'https://opencv.org/'],
            ['tensorflow', 'https://www.tensorflow.org/'],
            ['flask', 'https://flask.palletsprojects.com/en/2.0.x/'],
            ['gcp', 'https://cloud.google.com/']
        ],
        'key_points': [
            'Developed a custom deep learning architecture alongside start-of-the-art to categorise products',
            'Leveraged image processing and computer vision techniques to augment and transform images before inference',
            'Deployed the algorithm as a REST API endpoint with documentation and developed a re-training pipeline'
        ],
        'main_image': 'img/graphics/projects/...',
        'secondary_images': [
            'img/graphics/projects/...',
            'img/graphics/projects/...',
            'img/graphics/projects/...'
        ],
        'impact': [
            'Improved efficiency by 87.5%, based on the time taken to previously categorise products manually',
            'The algorithm was able to identify the correct product category with 97% accuracy',
            'The algorithm was integrated into the technology stack to help them ingest over 1000000 products online'
        ]
    },
    {
        'id': 'cervical-plate-identification',
        'name': 'Cervical Plate Identification',
        'header': 'Using image processing and computer vision to identify cervical plates from X-ray images',
        'client_name': 'Whatzit',
        'client_location': 'Texas, USA',
        'client_industry': 'Healthcare',
        'short_description': 'Identification of surgical plates within a humans body from an X-ray image is a manual task completed by an expert. This project aimed to bring automation and intelligence to this process by identifying almost 300 different types of plates using computer vision techniques.',
        'long_description': 'Identification of surgical plates within a humans body from an X-ray image is a manual task completed by an expert. This project aimed to bring automation and intelligence to this process by identifying almost 300 different types of plates using computer vision techniques.',
        'colour_theme': 'primary-gradient',
        'colour': 'primary',
        'tech_areas': ['Artificial Intelligence', 'Machine Learning', 'Computer Vision'],
        'tech_stack': [
            ['python', 'https://www.python.org/'],
            ['opencv', 'https://opencv.org/'],
            ['numpy', 'https://numpy.org/'],
            ['flask', 'https://flask.palletsprojects.com/en/2.0.x/'],
            ['aws', 'https://aws.amazon.com/']
        ],
        'key_points': [
            'Developed an algorithm to identify over 300 different implant systems from x-ray images',
            'Created a custom image processing pipeline to augment, transform and enrich the dataset',
            'Integrated the algorithm into their mobile application as a REST API endpoint with documentation'
        ],
        'main_image': 'img/graphics/projects/...',
        'secondary_images': [
            'img/graphics/projects/...',
            'img/graphics/projects/...',
            'img/graphics/projects/...'
        ],
        'impact': [
            'Enabled the business to identify cervical plates with up to 100% accuracy for some plates',
            'Compared our methodology annd results to an academic benchmark and beat it with a dataset 30x the size',
            'The business was now able to flag poor images to encourage users to upload a better quality one'
        ]
    },
    {
        'id': 'retail-demand-forecast',
        'name': 'Advanced Retail Demand Forecast',
        'header': 'Forecasting future demand for retail products at different hierarchical levels',
        'client_name': 'Best & Less',
        'client_location': 'Sydney, Australia',
        'client_industry': 'Retail',
        'short_description': 'COVID affected retail brand hugely. This project aimed to accurately forecast the demand of retail products with a 3, 6 and 12 month view. The forecast was built bottom-up, generating over 100000 SKU forecasts. The results were then measured at different hierarchical levels, such as product cluster, product category and geographical location.',
        'long_description': 'COVID affected retail brand hugely. This project aimed to accurately forecast the demand of retail products with a 3, 6 and 12 month view. The forecast was built bottom-up, generating over 100000 SKU forecasts. The results were then measured at different hierarchical levels, such as product cluster, product category and geographical location. Advanced machine learning and time series forecasting techniques were used to forecast demand at 97% accuracy.',
        'colour_theme': 'info-gradient',
        'colour': 'info',
        'tech_areas': ['Artificial Intelligence', 'Time Series Analysis', 'Forecasting'],
        'tech_stack': [
            ['python', 'https://www.python.org/'],
            ['tensorflow', 'https://www.tensorflow.org/'],
            ['numpy', 'https://numpy.org/']
        ],
        'key_points': [
            "Generated hierarchical, bottom-up demand forecasts for thousands of products across hundreds of retail stores",
            'Created an algorithm to project forecasted demand with a 3, 6 and 12 month view of the future',
            'Developed custom algorithms combining techniques from time series analysis, machine learning & deep learning'
        ],
        'main_image': 'img/graphics/projects/...',
        'secondary_images': [
            'img/graphics/projects/...',
            'img/graphics/projects/...',
            'img/graphics/projects/...'
        ],
        'impact': [
            "Enabled the business to predict demand for 100000's of products within minutes",
            'The model was able to accurately predict demand of products over 3 months at 97% accuracy',
            'The algorithms were able to uncover geographical, product and seasonal insights that helped to support business decisions'
        ]
    },
    {
        'id': 'telco-device-coordinates-prediction',
        'name': 'Mobile device coordinates prediction',
        'header': 'Mining huge datasets to predict coordinates of mobile devices',
        'client_name': '',
        'client_location': 'Jerusalem, Israel',
        'client_industry': 'Telco',
        'short_description': 'Telecommunications produces a huge amount of data. This project was focused on using the vast amount of data available, as well as other sources, to accurately predict the coordinate location of the mobile device within just a few metres of accuracy.',
        'long_description': 'Telecommunications produces a huge amount of data. This project was focused on using the vast amount of data available, as well as other sources, to accurately predict the coordinate location of the mobile device within just a few metres of accuracy.',
        'colour_theme': 'warning-gradient',
        'colour': 'warning',
        'tech_areas': ['Artificial Intelligence', 'Big Data', 'Geographical Analysis', 'Data Mining'],
        'tech_stack': [
            ['python', 'https://www.python.org/'],
            ['tensorflow', 'https://www.tensorflow.org/'],
            ['numpy', 'https://numpy.org/'],
            ['aws', 'https://aws.amazon.com/'],
            ['dask', 'https://dask.org/']
        ],
        'key_points': [
            "Leveraged AWS SageMaker & Dask to train models with huge amounts of data using GPU's",
            'Created an extensive feature engineering and dimensionality reduction pipeline for predictive modelling',
            'Translated predictions into business relevant metrics such as RMSE and Haversine distance'
        ],
        'main_image': 'img/graphics/projects/...',
        'secondary_images': [
            'img/graphics/projects/...',
            'img/graphics/projects/...',
            'img/graphics/projects/...'
        ],
        'impact': [
            'Predicted the location of devices with an accuracy of 0.00002 RMSE',
            'Developed custom algorithms for the business leveraging deep learning and machine learning approaches',
            'Created Jupyter notebooks to document and share code with the business'
        ]
    },
    {
        'id': 'nft-optimisation-engine',
        'name': 'NFT optimisation engine',
        'header': 'Creating an intelligent engine to optimise how NFT marketplace users can make smarter decisions',
        'client_name': '',
        'client_location': 'London, UK',
        'client_industry': 'Technology',
        'short_description': 'We are creating the first optimisation engine for NFT marketplaces',
        'long_description': 'We are creating the first optimisation engine for NFT marketplaces to allow users, both buyers and sellers, to make smarter decisions instead of just relying on a hunch.',
        'colour_theme': 'warning-gradient',
        'colour': 'warning',
        'tech_areas': ['Artificial Intelligence', 'Blockchain', 'NFT'],
        'tech_stack': [
            ['python', 'https://www.python.org/'],
            ['django', 'https://www.djangoproject.com/'],
            ['react', 'https://reactjs.org/'],
            ['postgres', 'https://www.postgresql.org/'],
            ['aws', 'https://aws.amazon.com/']
        ],
        'key_points': [
            'Building a user-friendly interface to allow everyday users to get a deeper understanding of NFTs',
            'Providing reasoning and clarity behind buying and selling NFTs, instead of just hype',
            'Using machine learning to personalise the service and to understand patterns and trends in the market'
        ],
        'main_image': 'img/graphics/projects/...',
        'secondary_images': [
            'img/graphics/projects/...',
            'img/graphics/projects/...',
            'img/graphics/projects/...'
        ],
        'impact': [
            'Aims to spread knowledge and insight across the market about NFTs, instead of pure hype',
            'Will aid users when buying and selling NFTs to make more profit and make better decisions',
            'Will help users to create better NFTs by providing insights that can support their creative process'
        ]
    },
    {
        'id': 'immersive-therapeutic-experience',
        'name': 'Intelligent Immersive Experience for Mental Health',
        'header': 'Creating a truly personalised solution for managing mental health',
        'client_name': '',
        'client_location': 'Guildford, UK',
        'client_industry': 'Mental Health',
        'short_description': 'This was a research project focused on developing an autonomous system that was able to dynamically & intelligently update itself in order to manage the users sensed mental state.',
        'long_description': 'This was a research project focused on developing an autonomous system that was able to dynamically & intelligently update itself in order to manage the users sensed mental state.',
        'colour_theme': 'info-gradient',
        'colour': 'info',
        'tech_areas': ['Artificial Intelligence', 'Reinforcement Learning', 'Virtual Reality', 'IoT'],
        'tech_stack': [
            ['python', 'https://www.python.org/'],
            ['flask', 'https://flask.palletsprojects.com/en/2.0.x/'],
            ['openai', 'https://openai.com/'],
            ['unity', 'https://unity.com/']
        ],
        'key_points': [
            "Built an autonomous system that used sensory feedback and optimise itself and manage the users mental health state",
            'Leveraged state-of-the-art AI algorithms to develop an intelligent system using reinforcement learning',
            'Created 3 dynamic & immersive environments for virtual reality including a Forest, Snow and Desert world'
        ],
        'main_image': 'img/graphics/projects/...',
        'secondary_images': [
            'img/graphics/projects/...',
            'img/graphics/projects/...',
            'img/graphics/projects/...'
        ],
        'impact': [
            'Integrated VR, AI & IoT into a lightweight, portable and interoperable system',
            'Was successfully able to regulate and measure a users mental state using sensory data',
            'Created a plan to scale and improve the system with more testing, data and technology enhancements',
        ]
    },
    {
        'id': 'voice-concierge-system',
        'name': 'Intelligent voice enabled concierge',
        'header': 'Creating the next generation of voice service in Hospitality',
        'client_name': '',
        'client_location': 'London, UK',
        'client_industry': 'Hospitality',
        'short_description': 'We have developed a truly unique voice ordering concierge system for Hospitality, whilst leveraging ubiquitous technology that people know and love.',
        'long_description': 'We have developed a truly unique voice ordering concierge system for Hospitality, whilst leveraging ubiquitous technology that people know and love.',
        'colour_theme': 'primary-gradient',
        'colour': 'primary',
        'tech_areas': ['Artificial Intelligence', 'Voice Activation', 'IoT'],
        'tech_stack': [
            ['python', 'https://www.python.org/'],
            ['django', 'https://www.djangoproject.com/'],
            ['react', 'https://reactjs.org/'],
            ['postgres', 'https://www.postgresql.org/'],
            ['aws', 'https://aws.amazon.com/']
        ],
        'key_points': [
            'Created an end-to-end voice concierge system to enhance the experience for users and optimise operations for properties',
            'Developed custom voice applications with personalised responses based on time, day, events and more',
            'Created a dashboard to enable a properties to monitor, review, analyse and manage requests in real-time'
        ],
        'main_image': 'img/graphics/projects/...',
        'secondary_images': [
            'img/graphics/projects/...',
            'img/graphics/projects/...',
            'img/graphics/projects/...'
        ],
        'impact': [
            'Each request by a guest has been found to yield a profit of £45 when the request is fulfilled by the property',
            'Reduced operational costs on event days and increased efficiency of operations of working staff',
            'Properties have gained insights about customer experience and interactions like never before',
        ]
    },
    {
        'id': 'ango-product-recommendation',
        'name': 'Sustainable Product Recommendation',
        'header': 'Using computer vision & NLP to recommend sustainable products',
        'client_name': 'Ango',
        'client_location': 'Bournemouth, UK',
        'client_industry': 'Sustainability',
        'short_description': 'Ango is a sustainable retail platform that helped users to make better buying decisions and to buy sustainable alternatives when shopping online. We helped Ango to build a recommendation engine that suggests sustainable alternatives when shopping online.',
        'long_description': 'Ango is a sustainable retail platform that helped users to make better buying decisions and to buy sustainable alternatives when shopping online. We helped Ango to build a recommendation engine that suggests sustainable alternatives when shopping online.',
        'colour_theme': 'success-gradient',
        'colour': 'success',
        'tech_areas': ['Artificial Intelligence', 'Deep Learning', 'Computer Vision'],
        'tech_stack': [
            ['python', 'https://www.python.org/'],
            ['opencv', 'https://opencv.org/'],
            ['tensorflow', 'https://www.tensorflow.org/'],
            ['flask', 'https://flask.palletsprojects.com/en/2.0.x/'],
            ['gcp', 'https://cloud.google.com/']
        ],
        'key_points': [
            'Developed custom recommendation algorithms to recommend relevant sustainable alternatives to online shoppers',
            'Leveraged computer vision and NLP techniques to recommend the most relevant alternatives',
            'Deployed the algorithm as a REST API with documentation within a microservices architecture'
        ],
        'main_image': 'img/graphics/projects/...',
        'secondary_images': [
            'img/graphics/projects/...',
            'img/graphics/projects/...',
            'img/graphics/projects/...'
        ],
        'impact': [
            'Generated sustainable recommendations for over 1000000 products online',
            'Created a multi-modal recommendation model using website information and images',
            'Development and integration of the algorithm enabled the business to raise further seed funding'
        ]
    },
    {
        'id': 'storyroom-topic-clustering',
        'name': 'Semantic Keyword Clustering',
        'header': 'Clustering keywords into relevant topic clusters with semantic meaning and naming',
        'client_name': 'Storyroom',
        'client_location': 'California, USA',
        'client_industry': 'Technology',
        'short_description': 'This project aimed to encode keyword strings intuitively within a high dimensional feature space to form a clustering model that can assign semantic meaning to keywords.',
        'long_description': 'This project aimed to encode keyword strings intuitively within a high dimensional feature space to form a clustering model that can assign semantic meaning to keywords.',
        'colour_theme': 'dark-gradient',
        'colour': 'dark',
        'tech_areas': ['Artificial Intelligence', 'Deep Learning', 'Natural Language Processing'],
        'tech_stack': [
            ['python', 'https://www.python.org/'],
            ['neo4j', 'https://neo4j.com/'],
            ['spacy', 'https://spacy.io/'],
            ['flask', 'https://flask.palletsprojects.com/en/2.0.x/'],
            ['aws', 'https://aws.amazon.com/']
        ],
        'key_points': [
            'Created an algorithm to cluster keyword strings based on semantic meaning',
            'Used machine learning, statistical techniques and business rules to intuitively cluster keywords',
            'Leveraged and built a graph database to enhance the relationships between keywords within a cluster'
        ],
        'main_image': 'img/graphics/projects/...',
        'secondary_images': [
            'img/graphics/projects/...',
            'img/graphics/projects/...',
            'img/graphics/projects/...'
        ],
        'impact': [
            'Created an algorithm capable of assigning known and unseen keywords to existing or new clusters',
            'Built the algorithm to cluster keywords hierarchically for a delightful user experience',
            'Built a feedback system to enhance the clustering and to create new connections between keywords'
        ]
    },
];

export default projects;