import React, { Fragment } from 'react'

const Approach = () => {
    return (
        <Fragment>
            <div className="container">

                {/* Header */}
                <div className="row text-center d-flex justify-content-center pt-30">
                    <h1 className="display-1 welcome-text text-black front">Our approach</h1>
                </div>
                <div className="row text-center d-flex justify-content-center pb-10">
                    <h3 className="welcome-text text-muted front">We use a simple 3 pillared approach when building solutions</h3>
                </div>

                {/* Three pillars */}
                <div className="row text-center justify-content-center mt-20">

                    {/* Purpose */}
                    <div className="col-lg-4 col-md-4 col-sm-12 jump mb-10">
                        <div className='card jump hover-icon-primary border-0 rounded-xxl p-10'>

                            <span className="svg-icon svg-icon-dark svg-icon-10x">
                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                        <path d="M12,16 C12.5522847,16 13,16.4477153 13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 C11,16.4477153 11.4477153,16 12,16 Z M10.591,14.868 L10.591,13.209 L11.851,13.209 C13.447,13.209 14.602,11.991 14.602,10.395 C14.602,8.799 13.447,7.581 11.851,7.581 C10.234,7.581 9.121,8.799 9.121,10.395 L7.336,10.395 C7.336,7.875 9.31,5.922 11.851,5.922 C14.392,5.922 16.387,7.875 16.387,10.395 C16.387,12.915 14.392,14.868 11.851,14.868 L10.591,14.868 Z" fill="#000000" />
                                    </g>
                                </svg>
                            </span>
                            <figure className="text-center mb-0 mt-5">
                                <blockquote className="blockquote">
                                    <h1 className="card-title font-weight-bolder mb-3 text-black">Purpose</h1>
                                    <p className="card-text text-black">
                                        To create an incredible technology solution, we first dive deep to understand what problem you're solving and why you want to solve it.
                                    </p>
                                </blockquote>
                            </figure>

                        </div>
                    </div>

                    {/* Creativity */}
                    <div className="col-lg-4 col-md-4 col-sm-12 jump mb-10">
                        <div className='card jump hover-icon-danger border-0 rounded-xxl p-10'>

                            <span className="svg-icon svg-icon-dark svg-icon-10x">
                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <path d="M14,7 C13.6666667,10.3333333 12.6666667,12.1167764 11,12.3503292 C11,12.3503292 12.5,6.5 10.5,3.5 C10.5,3.5 10.287918,6.71444735 8.14498739,10.5717225 C7.14049032,12.3798172 6,13.5986793 6,16 C6,19.428689 9.51143904,21.2006583 12.0057195,21.2006583 C14.5,21.2006583 18,20.0006172 18,15.8004732 C18,14.0733981 16.6666667,11.1399071 14,7 Z" fill="#000000" />
                                    </g>
                                </svg>
                            </span>
                            <figure className="text-center mb-0 mt-5">
                                <blockquote className="blockquote">
                                    <h1 className="card-title font-weight-bolder mb-3 text-black">Creativity</h1>
                                    <p className="card-text text-black">
                                        We use our creative expertise and design skills to plan and architect a solution just for you. Fundamentally, we aim to inject innovative flair into any project.
                                    </p>
                                </blockquote>
                            </figure>

                        </div>
                    </div>

                    {/* Optimise */}
                    <div className="col-lg-4 col-md-4 col-sm-12 jump mb-10">
                        <div className='card jump hover-icon-info border-0 rounded-xxl p-10'>

                            <span className="svg-icon svg-icon-dark svg-icon-10x">
                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <path d="M18,15 L18,13.4774152 C18,13.3560358 18.0441534,13.2388009 18.1242243,13.147578 C18.3063883,12.9400428 18.622302,12.9194754 18.8298372,13.1016395 L21.7647988,15.6778026 C21.7814819,15.6924462 21.7971714,15.7081846 21.811763,15.7249133 C21.9932797,15.933015 21.9717282,16.2488631 21.7636265,16.4303797 L18.828665,18.9903994 C18.7375973,19.0698331 18.6208431,19.1135979 18.5,19.1135979 C18.2238576,19.1135979 18,18.8897403 18,18.6135979 L18,17 L16.445419,17 C14.5938764,17 12.8460429,16.1451629 11.7093057,14.6836437 L7.71198984,9.54423755 C6.95416504,8.56989138 5.7889427,8 4.55458097,8 L2,8 L2,6 L4.55458097,6 C6.40612357,6 8.15395708,6.85483706 9.29069428,8.31635632 L13.2880102,13.4557625 C14.045835,14.4301086 15.2110573,15 16.445419,15 L18,15 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                        <path d="M18,6 L18,4.4774157 C18,4.3560363 18.0441534,4.23880134 18.1242243,4.14757848 C18.3063883,3.94004327 18.622302,3.9194759 18.8298372,4.10163997 L21.7647988,6.67780304 C21.7814819,6.69244668 21.7971714,6.70818509 21.811763,6.72491379 C21.9932797,6.93301548 21.9717282,7.24886356 21.7636265,7.43038021 L18.828665,9.99039986 C18.7375973,10.0698336 18.6208431,10.1135984 18.5,10.1135984 C18.2238576,10.1135984 18,9.88974079 18,9.61359842 L18,8 L16.445419,8 C15.2110573,8 14.045835,8.56989138 13.2880102,9.54423755 L9.29069428,14.6836437 C8.15395708,16.1451629 6.40612357,17 4.55458097,17 L2,17 L2,15 L4.55458097,15 C5.7889427,15 6.95416504,14.4301086 7.71198984,13.4557625 L11.7093057,8.31635632 C12.8460429,6.85483706 14.5938764,6 16.445419,6 L18,6 Z" fill="#000000" fillRule="nonzero" />
                                    </g>
                                </svg>
                            </span>
                            <figure className="text-center mb-0 mt-5">
                                <blockquote className="blockquote">
                                    <h1 className="card-title font-weight-bolder mb-3 text-black">Optimise</h1>
                                    <p className="card-text text-black">
                                        We approach every project with a fresh pair of eyes. Everything we do is personalised and optimised for your business and specific use case.
                                    </p>
                                </blockquote>
                            </figure>

                        </div>
                    </div>

                </div>

            </div>
        </Fragment>
    )
}

export default Approach
