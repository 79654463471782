import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import About from './components/about/About';
import Services from './components/services/Services';
import Landing from './components/landing/Landing';
import NotFound from './components/routing/NotFound';
import Portfolio from './components/portfolio/Portfolio';
import Alert from './components/layout/Alert';

// Redux
import { Provider } from 'react-redux';
import store from './store';

function App() {

	return (
		<Provider store={store}>
			<Router>
				<Fragment>
					<Alert />
					<Routes>
						<Route path="/" element={<Landing />} />
						<Route path="/about" element={<About />} />
						<Route path="/services" element={<Services />} />
						<Route path="/portfolio" element={<Portfolio />} />
						<Route path="*" element={<NotFound />} />
					</Routes>
				</Fragment>
			</Router>
		</Provider>
	);
}

export default App;