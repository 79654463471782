import React, { Fragment } from 'react'
import Masonry from 'react-masonry-css';
import reviews from '../../data/reviews';
import ReviewCard from './ReviewCard';

const Reviews = () => {

    const breakpoints = {
        default: 3,
        1200: 3,
        796: 2,
        500: 1
    }
    return (
        <Fragment>
            <div className="container">
                <div id="testimonials" style={{ 'position': 'relative', 'top': '-150px' }}></div>
                <h1 className='display-1 welcome-text text-black text-center front mb-30'>And what people have said</h1>
                <Masonry breakpointCols={breakpoints} className="masonry-grid" columnClassName="masonry-grid-column">
                    {reviews.map(x => (
                        <ReviewCard data={x} key={x['id']} />
                    ))}
                </Masonry>
            </div>
        </Fragment>
    )
}

export default Reviews
