import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import formatProjectsShort from '../../utils/formatProjectsShort';
import TechIcon from '../layout/TechIcon';

const ProjectHorizontalScroll = () => {

    // Format projects
    const [ltrProjects, rtlProjects] = formatProjectsShort();

    return (
        <Fragment>
            <div className="container-fluid">
                <h1 className='display-1 welcome-text text-black text-center front mb-30'>Look at what we've built</h1>
                <div className="row flex-row flex-nowrap overflow-auto projects-scroll projects-scroll-right">

                    {/* Create rows of cards showing projects */}
                    {ltrProjects.map((x, idx) => (
                        // <div className={`card bg-${x['colour_theme']} mr-10 rounded-xxl`} style={{ 'width': '400px' }} key={idx}>
                        <div className={`card border-0 bg-white mr-10 rounded-xxl`} style={{ 'width': '400px' }} key={idx}>
                            <Link to={`/portfolio/#${x['id']}`} target="_blank">
                                <div className="card-body">

                                    {/* Name */}
                                    <h3 className="card-title text-black mb-3">{x['name']}</h3>

                                    {/* Header */}
                                    <h5 className="card-title text-muted mt-0"><em>{x['header']}</em></h5>

                                    {/* Industry */}
                                    <div className="row justify-content-left">
                                        <button className={`btn btn-icon-${x['colour']} text-black font-size-lg text-left py-0`} href="#!">
                                            <span className="svg-icon svg-icon-2x mr-3">
                                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                                        <rect fill="#000000" x="11" y="10" width="2" height="7" rx="1" />
                                                        <rect fill="#000000" x="11" y="7" width="2" height="2" rx="1" />
                                                    </g>
                                                </svg>
                                            </span>{x['industry']}
                                        </button>
                                    </div>

                                    {/* Location */}
                                    <div className="row justify-content-left">
                                        <button className={`btn btn-icon-${x['colour']} text-black font-size-lg text-left py-4`} href="#!">
                                            <span className="svg-icon svg-icon-2x mr-3">
                                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <path d="M5,10.5 C5,6 8,3 12.5,3 C17,3 20,6.75 20,10.5 C20,12.8325623 17.8236613,16.03566 13.470984,20.1092932 C12.9154018,20.6292577 12.0585054,20.6508331 11.4774555,20.1594925 C7.15915182,16.5078313 5,13.2880005 5,10.5 Z M12.5,12 C13.8807119,12 15,10.8807119 15,9.5 C15,8.11928813 13.8807119,7 12.5,7 C11.1192881,7 10,8.11928813 10,9.5 C10,10.8807119 11.1192881,12 12.5,12 Z" fill="#000000" fillRule="nonzero" />
                                                    </g>
                                                </svg>
                                            </span>{x['location']}
                                        </button>
                                    </div>

                                    {/* Tech stack */}
                                    <div className={`row justify-content-center text-center mt-5 bg-${x['colour']} px-10 py-5 rounded-xxl`}>
                                        {x['tech_stack'].slice(0, 3).map((y, y_idx) => (
                                            <div className="col" key={y_idx}>
                                                <button className="btn btn-icon btn-circle btn-icon-white border-0" type="button">
                                                    <TechIcon logo={y[0]} />
                                                </button>
                                            </div>
                                        ))}
                                    </div>

                                </div>
                            </Link>
                        </div>
                    ))}

                </div>

                <div className="row flex-row flex-nowrap overflow-auto projects-scroll projects-scroll-left mt-10">

                    {/* Create rows of cards showing projects */}
                    {rtlProjects.map((x, idx) => (
                        <div className={`card border-0 bg-white mr-10 rounded-xxl`} style={{ 'width': '400px' }} key={idx}>
                            <Link to={`/portfolio/#${x['id']}`} target="_blank">
                                <div className="card-body">

                                    {/* Name */}
                                    <h3 className="card-title text-black">{x['name']}</h3>

                                    {/* Header */}
                                    <h5 className="card-title text-muted mt-0"><em>{x['header']}</em></h5>

                                    {/* Industry */}
                                    <div className="row justify-content-left">
                                        <button className={`btn btn-icon-${x['colour']} text-black font-size-lg text-left py-0`} href="#!">
                                            <span className="svg-icon svg-icon-2x mr-3">
                                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                                        <rect fill="#000000" x="11" y="10" width="2" height="7" rx="1" />
                                                        <rect fill="#000000" x="11" y="7" width="2" height="2" rx="1" />
                                                    </g>
                                                </svg>
                                            </span>{x['industry']}
                                        </button>
                                    </div>

                                    {/* Location */}
                                    <div className="row justify-content-left">
                                        <button className={`btn btn-icon-${x['colour']} text-black font-size-lg text-left py-4`} href="#!">
                                            <span className="svg-icon svg-icon-2x mr-3">
                                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <path d="M5,10.5 C5,6 8,3 12.5,3 C17,3 20,6.75 20,10.5 C20,12.8325623 17.8236613,16.03566 13.470984,20.1092932 C12.9154018,20.6292577 12.0585054,20.6508331 11.4774555,20.1594925 C7.15915182,16.5078313 5,13.2880005 5,10.5 Z M12.5,12 C13.8807119,12 15,10.8807119 15,9.5 C15,8.11928813 13.8807119,7 12.5,7 C11.1192881,7 10,8.11928813 10,9.5 C10,10.8807119 11.1192881,12 12.5,12 Z" fill="#000000" fillRule="nonzero" />
                                                    </g>
                                                </svg>
                                            </span>{x['location']}
                                        </button>
                                    </div>

                                    {/* Tech stack */}
                                    <div className={`row justify-content-center text-center mt-5 bg-${x['colour']} px-10 py-5 rounded-xxl`}>
                                        {x['tech_stack'].slice(0, 3).map((y, y_idx) => (
                                            <div className="col" key={y_idx}>
                                                <button className="btn btn-icon btn-circle btn-icon-white border-0" type="button">
                                                    <TechIcon logo={y[0]} />
                                                </button>
                                            </div>
                                        ))}
                                    </div>

                                </div>
                            </Link>
                        </div>
                    ))}

                </div>
            </div>
        </Fragment>
    )
}

export default ProjectHorizontalScroll
