/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react'
import Contact from '../contact/Contact'
import Footer from '../layout/Footer'
import Header from '../layout/Header'
import Navbar from '../layout/Navbar'
import formatProjectsLong from '../../utils/formatProjectsLong';
import Spacer from '../layout/Spacer';
import TechIcon from '../layout/TechIcon'

const Portfolio = () => {

    // Format projects
    const projects = formatProjectsLong();

    return (
        <Fragment>
            <Navbar />

            <Header page="portfolio" />

            {/* Exhibition of projects */}
            {/* <svg width="100%" id="svg" viewBox="0 0 1440 400" xmlns="http://www.w3.org/2000/svg" className="transition duration-300 ease-in-out delay-150"><path d="M 0,400 C 0,400 0,200 0,200 C 77.42631398144968,174.2878735829612 154.85262796289936,148.57574716592237 224,160 C 293.14737203710064,171.42425283407763 354.0158021298524,219.98488491927168 419,231 C 483.9841978701476,242.01511508072832 553.0841635176914,215.48471315699075 621,192 C 688.9158364823086,168.51528684300925 755.6475437993817,148.07626245276535 819,155 C 882.3524562006183,161.92373754723465 942.3256612847817,196.21023703194777 1014,219 C 1085.6743387152183,241.78976296805223 1169.0498110614908,253.0827894194435 1242,248 C 1314.9501889385092,242.9172105805565 1377.4750944692546,221.45860529027826 1440,200 C 1440,200 1440,400 1440,400 Z" stroke="none" strokeWidth="0" fill="#d1d3e0ff" className="transition-all duration-300 ease-in-out delay-150 path-0"></path></svg> */}
            {/* <div className="vw-100 bg-gray-400"> */}
            <div className="vw-100">
                {projects.map((x, idx) => (
                    <Fragment key={idx} >
                        <div id={x['id']} style={{ 'position': 'relative', 'top': '-150px' }}></div>
                        {/* <div className={`row justify-content-${x['alignment'] === 'left' ? 'start' : 'end'} py-20`}> */}
                        <div className={`row justify-content-center py-20`}>
                            {/* <div className={`portfolio-card jump card rounded-xxl bg-${x['colour_theme']} rounded-lg border-0`}> */}
                            <div className={`portfolio-card jump card rounded-xxl bg-white rounded-xxl p-20 border-0`}>
                                <div className="card-body">

                                    {/* Name */}
                                    <h1 className="card-title text-black mb-3 font-weight-bolder">{x['name']}</h1>

                                    {/* Header */}
                                    <h3 className="card-title text-muted"><em>{x['header']}</em></h3>

                                    {/* Industry */}
                                    <div className="row justify-content-left">
                                        <button className={`btn btn-icon-${x['colour']} text-black font-size-lg text-left py-0`} href="#!">
                                            <span className={`svg-icon svg-icon-2x mr-3`}>
                                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                                        <rect fill="#000000" x="11" y="10" width="2" height="7" rx="1" />
                                                        <rect fill="#000000" x="11" y="7" width="2" height="2" rx="1" />
                                                    </g>
                                                </svg>
                                            </span>{x['industry']}
                                        </button>
                                    </div>

                                    {/* Location */}
                                    <div className="row justify-content-left mb-3">
                                        <button className={`btn btn-icon-${x['colour']} text-black font-size-lg text-left py-4`} href="#!">
                                            <span className="svg-icon svg-icon-2x mr-3">
                                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <path d="M5,10.5 C5,6 8,3 12.5,3 C17,3 20,6.75 20,10.5 C20,12.8325623 17.8236613,16.03566 13.470984,20.1092932 C12.9154018,20.6292577 12.0585054,20.6508331 11.4774555,20.1594925 C7.15915182,16.5078313 5,13.2880005 5,10.5 Z M12.5,12 C13.8807119,12 15,10.8807119 15,9.5 C15,8.11928813 13.8807119,7 12.5,7 C11.1192881,7 10,8.11928813 10,9.5 C10,10.8807119 11.1192881,12 12.5,12 Z" fill="#000000" fillRule="nonzero" />
                                                    </g>
                                                </svg>
                                            </span>{x['location']}
                                        </button>
                                    </div>

                                    {/* Description */}
                                    <p className="text-black mb-5">{x['long_description']}</p>

                                    {/* Tech areas */}
                                    {/* <h4 className="card-title text-black">Tech areas covered</h4> */}
                                    <div className={`row justify-content-center text-center my-10 bg-${x['colour']} px-10 py-5 rounded-xxl`}>
                                        {x['tech_stack'].map((y, y_idx) => (
                                            <div className="col" key={y_idx}>
                                                <a className="btn btn-icon btn-circle btn-icon-white border-0 py-10" href={y[1]} target="_blank" rel="noreferrer">
                                                    <TechIcon logo={y[0]} />
                                                </a>
                                            </div>
                                        ))}
                                    </div>

                                    {/* Key points and impact */}
                                    <div className="row justify-content-center">

                                        {/* Key points */}
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                            <h4 className="card-title text-black mt-8 mb-4">Key points</h4>
                                            {x['key_points'].map((y, y_idx) => (
                                                <div className="row text-left align-items-center mb-5 mr-5">
                                                    <div className="col">
                                                        <span class={`svg-icon svg-icon-${x['colour']} svg-icon-2x mr-2 py-30`}>
                                                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <polygon points="0 0 24 0 24 24 0 24" />
                                                                    <path d="M9.26193932,16.6476484 C8.90425297,17.0684559 8.27315905,17.1196257 7.85235158,16.7619393 C7.43154411,16.404253 7.38037434,15.773159 7.73806068,15.3523516 L16.2380607,5.35235158 C16.6013618,4.92493855 17.2451015,4.87991302 17.6643638,5.25259068 L22.1643638,9.25259068 C22.5771466,9.6195087 22.6143273,10.2515811 22.2474093,10.6643638 C21.8804913,11.0771466 21.2484189,11.1143273 20.8356362,10.7474093 L17.0997854,7.42665306 L9.26193932,16.6476484 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(14.999995, 11.000002) rotate(-180.000000) translate(-14.999995, -11.000002) " />
                                                                    <path d="M4.26193932,17.6476484 C3.90425297,18.0684559 3.27315905,18.1196257 2.85235158,17.7619393 C2.43154411,17.404253 2.38037434,16.773159 2.73806068,16.3523516 L11.2380607,6.35235158 C11.6013618,5.92493855 12.2451015,5.87991302 12.6643638,6.25259068 L17.1643638,10.2525907 C17.5771466,10.6195087 17.6143273,11.2515811 17.2474093,11.6643638 C16.8804913,12.0771466 16.2484189,12.1143273 15.8356362,11.7474093 L12.0997854,8.42665306 L4.26193932,17.6476484 Z" fill="#000000" fill-rule="nonzero" transform="translate(9.999995, 12.000002) rotate(-180.000000) translate(-9.999995, -12.000002) " />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        <span className="text-black" key={y_idx}>{y}</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        {/* Impact */}
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                            <h4 className="card-title text-black mt-8 mb-4">Impact</h4>
                                            {x['impact'].map((y, y_idx) => (
                                                <div className="row text-left align-items-center mb-5 mr-5">
                                                    <div className="col">
                                                        <span class={`svg-icon svg-icon-${x['colour']} svg-icon-2x mr-2`}>
                                                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                    <rect x="0" y="0" width="24" height="24" />
                                                                    <path d="M16.3740377,19.9389434 L22.2226499,11.1660251 C22.4524142,10.8213786 22.3592838,10.3557266 22.0146373,10.1259623 C21.8914367,10.0438285 21.7466809,10 21.5986122,10 L17,10 L17,4.47708173 C17,4.06286817 16.6642136,3.72708173 16.25,3.72708173 C15.9992351,3.72708173 15.7650616,3.85240758 15.6259623,4.06105658 L9.7773501,12.8339749 C9.54758575,13.1786214 9.64071616,13.6442734 9.98536267,13.8740377 C10.1085633,13.9561715 10.2533191,14 10.4013878,14 L15,14 L15,19.5229183 C15,19.9371318 15.3357864,20.2729183 15.75,20.2729183 C16.0007649,20.2729183 16.2349384,20.1475924 16.3740377,19.9389434 Z" fill="#000000" />
                                                                    <path d="M4.5,5 L9.5,5 C10.3284271,5 11,5.67157288 11,6.5 C11,7.32842712 10.3284271,8 9.5,8 L4.5,8 C3.67157288,8 3,7.32842712 3,6.5 C3,5.67157288 3.67157288,5 4.5,5 Z M4.5,17 L9.5,17 C10.3284271,17 11,17.6715729 11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L4.5,20 C3.67157288,20 3,19.3284271 3,18.5 C3,17.6715729 3.67157288,17 4.5,17 Z M2.5,11 L6.5,11 C7.32842712,11 8,11.6715729 8,12.5 C8,13.3284271 7.32842712,14 6.5,14 L2.5,14 C1.67157288,14 1,13.3284271 1,12.5 C1,11.6715729 1.67157288,11 2.5,11 Z" fill="#000000" opacity="0.3" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        <span className="text-black" key={y_idx}>{y}</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </Fragment>
                ))}


            </div>
            {/* <svg width="100%" id="svg" viewBox="0 0 1440 400" xmlns="http://www.w3.org/2000/svg" className="transition duration-300 ease-in-out delay-150"><path d="M 0,400 C 0,400 0,200 0,200 C 52.327722432153905,171.89625558227414 104.65544486430781,143.7925111645483 169,149 C 233.3445551356922,154.2074888354517 309.7059429749227,192.72621092408104 388,221 C 466.2940570250773,249.27378907591896 546.5207832360013,267.3026451391275 630,260 C 713.4792167639987,252.6973548608725 800.2109240810718,220.0632085194091 861,189 C 921.7890759189282,157.9367914805909 956.6355204397114,128.44452078323602 1010,129 C 1063.3644795602886,129.55547921676398 1135.2469941600825,160.15870834764684 1210,177 C 1284.7530058399175,193.84129165235316 1362.3765029199587,196.92064582617658 1440,200 C 1440,200 1440,400 1440,400 Z" stroke="none" strokeWidth="0" fill="#d1d3e0ff" className="transition-all duration-300 ease-in-out delay-150 path-0" transform="rotate(-180 720 200)"></path></svg> */}
            <Spacer height="30" />

            {/* Get in touch */}
            {/* <Contact /> */}

            {/* Footer */}
            <Footer />

        </Fragment>
    )
}

export default Portfolio
