/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react'
import Contact from '../contact/Contact'
import Footer from '../layout/Footer'
import Header from '../layout/Header'
import Navbar from '../layout/Navbar'
import Spacer from '../layout/Spacer'
import Approach from './Approach'
import Intro from './Intro'
import Mission from './Mission'

const About = () => {
    return (
        <Fragment>
            <Navbar />

            <Header page="about" />

            {/* Intro */}
            <Intro />
            <Spacer height="15" />

            {/* Approach */}
            <Approach />
            <Spacer height="30" />


            {/* Mission */}
            <Mission />
            <Spacer height="30" />

            {/* Get in touch */}
            {/* <Contact /> */}

            {/* Footer */}
            <Footer />

        </Fragment>
    )
}

export default About
