import React, { Fragment } from 'react'

const Mission = () => {
    return (
        <Fragment>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill="#000000" fillOpacity="1" d="M0,224L1440,64L1440,320L0,320Z"></path>
            </svg>
            <div className="vw-100 bg-black">
                <div className="container">
                    <div className="row text-center d-flex justify-content-center pt-20">
                        <h1 className="display-1 welcome-text text-white front">Our mission</h1>
                    </div>
                    <div className="row text-center d-flex justify-content-center pb-30">
                        <h3 className="welcome-text text-white front">is to create meaningful technology that truly enriches and enhances human interactions and experiences. <br />Together, we can build a future where people and technology work hand-in-hand.</h3>
                    </div>
                </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill="#000000" fillOpacity="1" d="M0,224L1440,64L1440,0L0,0Z"></path>
            </svg>
        </Fragment>
    )
}

export default Mission
