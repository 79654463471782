import React, { Fragment } from 'react'
import Specialities from '../services/Specialities'

const Description = () => {
    return (
        <Fragment>
            <div className="container">

                {/* Description */}
                <div className="grid text-center d-flex justify-content-center flex-nowrap rounded-pill p-30">
                    <h1 className="display-2 welcome-text text-black front">We are a Research & Development company focused on human-centric use cases of technology.</h1>
                </div>

                <Specialities />

            </div>

        </Fragment >
    )
}

export default Description
