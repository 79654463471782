const reviews = [
    {
        'id': 'tomer-mobile-coords',
        'name': 'Tomer',
        'text': 'Halo are proficient and knowledgeable, they have a grasp on many relevant subject and have the ability to transfer knowledge in a clear and efficient manner. In addition, they are very nice people to interact with which make the whole experience even better.',
        'services': [
            'Data Science',
            'Machine Learning'
        ]
    },
    {
        'id': 'oscar-ts-forecasting',
        'name': 'Thomas',
        'text': "The experts at Halo are world class. Both in their craft and communication of their method for delivery. Some of the best data scientists I've worked with (among over 200 employees the past 12 years).",
        'services': [
            'Data Science',
            'Machine Learning',
            'Statistical Analysis'
        ]
    },
    {
        'id': 'tarek-2',
        'name': 'Tarek',
        'text': "Halo did a great job. They handle tasks as an owner and make sure to look at every tiny detail and to fix any issues if any. I recommend Halo for your Data Science projects.",
        'services': [
            'Data Science',
            'Machine Learning',
            'Statistical Analysis'
        ]
    },
    {
        'id': 'tarek-1',
        'name': 'Tamara',
        'text': "They have tonnes of experience and this shows in their work. They communicate complex ideas in a very simple way. Halo are well versed with machine learning and data munging techniques and have an eye to read between the lines and uncover very insights from the data. Halo is the kind of organisation you can throw any problem at and they will come back with a clear and understandable solution without any hand-holding.",
        'services': [
            'Data Science',
            'Machine Learning',
            'Statistical Analysis'
        ]
    },
    {
        'id': 'forage-1',
        'name': 'Joe',
        'text': "Halo delivers high quality work on time.",
        'services': [
            'Data Science',
            'Machine Learning',
            'Statistical Analysis'
        ]
    },
    {
        'id': 'sap-1',
        'name': 'Dean',
        'text': "It has been an absolute pleasure working with Halo over the last year. They show enthusiasm and exudes professionalism whenever I work with them. Their experts are very comfortable and competent in front of customers.",
        'services': [
            'Data Science',
            'Machine Learning',
            'Statistical Analysis'
        ]
    },
    {
        'id': 'sap-2',
        'name': 'Paul',
        'text': "The level of professionalism and dedication that Halo has consistently enthused into our team and exuded on a daily basis deserves credit. From day one they will take on complex tasks with vigour and deliver results quickly and effectively. Halo are great team players as well as showing strong leadership qualities. Their experts have a fantastic ability to engage with an audience and are able to deliver confident public speaking messaging. I would not hesitate to recommend Halo as a top candidate for any business. I look forward to working with them again some time soon.",
        'services': [
            'Data Science',
            'Machine Learning',
            'Statistical Analysis'
        ]
    },
    {
        'id': 'sap-3',
        'name': 'Pete',
        'text': "Within the span of 6 months Halo has achieved more than one would expect with our team. I was particularly impressed with Halo's work ethic, positive attitude, and the speed in which they picked up new information. Halo has exceeded my expectations in working with an innovative technology partner. I would highly recommend Halo to any prospective business or partners.",
        'services': [
            'Data Science',
            'Machine Learning',
            'Statistical Analysis'
        ]
    },
    {
        'id': 'sap-4',
        'name': 'Michelle',
        'text': "I worked with Halo and found them to be incredibly personable, creative and diligent in their work. During our time of working together, Halo frequently came up with new and innovative ideas for on how to add more value to our business. The quality of their work is excellent and I really enjoyed working with them.",
        'services': [
            'Data Science',
            'Machine Learning',
            'Statistical Analysis'
        ]
    },
];

export default reviews;