import projects from '../data/projects';

function formatProjectsShort() {

    let formattedProjects1 = []
    let formattedProjects2 = []
    for (let i = 0; i < projects.length; i++) {
        let project = projects[i];
        let content = {
            'id': project['id'],
            'name': project['name'],
            'header': project['header'],
            'industry': project['client_industry'],
            'colour_theme': project['colour_theme'],
            'colour': project['colour'],
            'tech_stack': project['tech_stack'],
            'main_image': project['main_image'],
            'location': project['client_location']
        };
        if (i < projects.length / 2) {
            formattedProjects1.push(content);
        } else {
            formattedProjects2.push(content);
        }
    }

    return [formattedProjects1, formattedProjects2];
};

export default formatProjectsShort;