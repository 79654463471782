import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { contactUs } from '../../actions/contact';
import { setAlert } from '../../actions/alert';


const Contact = ({ contactUs, setAlert }) => {

    const [formData, setFormData] = useState({
        email: '',
        header: '',
        body: ''
    });

    const onFormDataChange = e => setFormData({
        ...formData,
        [e.target.name]: e.target.value
    });

    const submitForm = e => {
        e.preventDefault();
        if (!formData.email || !formData.header || !formData.body) {
            return setAlert('Please fill in all the required fields!', 'danger')
        }
        contactUs(formData.email, formData.header, formData.body)
        setFormData({ email: '', header: '', body: '' });
    }

    return (
        <Fragment>
            <div id="contact" style={{ 'position': 'relative', 'top': '-150px' }}></div>
            <h1 className='display-1 welcome-text text-black text-center front mb-30'>Would you like to chat?</h1>
            <div className="container">


                {/* Contact form */}
                <Fragment>

                    <div className="container w-lg-50">

                        <div className="card mt-10 p-10 border-0 rounded-xxl" style={{ "backgroundColor": 'rgb(243, 243, 243)' }}>

                            {/* Email */}
                            <div className="form-group">
                                <input className="form-control h-auto px-6 py-6 rounded-xxl border-0" type="text" placeholder="What is your email address?" name="email" value={formData.email ? formData.email : ''} onChange={(e) => onFormDataChange(e)} required />
                            </div>

                            {/* Header */}
                            <div className="form-group">
                                <input className="form-control h-auto px-6 py-6 rounded-xxl border-0" type="text" placeholder="Give us a header for your message" name="header" value={formData.header ? formData.header : ''} onChange={(e) => onFormDataChange(e)} required />
                            </div>

                            {/* Body */}
                            <div className="form-group">
                                <textarea className="form-control h-auto px-6 py-6 rounded-xxl border-0" type="text" placeholder="What would you like to say?" name="body" value={formData.body ? formData.body : ''} onChange={(e) => onFormDataChange(e)} rows="10" required />
                            </div>

                            {/* Submit */}
                            <div className="form-group justify-content-center m-0">
                                <div className="col text-center">
                                    <a className="btn btn-info btn-icon-white btn-pill font-weight-bolder px-8 py-4" href="#!" onClick={(e) => submitForm(e)}>
                                        <span className="svg-icon">
                                            <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24" />
                                                    <path d="M8,13.1668961 L20.4470385,11.9999863 L8,10.8330764 L8,5.77181995 C8,5.70108058 8.01501031,5.63114635 8.04403925,5.56663761 C8.15735832,5.31481744 8.45336217,5.20254012 8.70518234,5.31585919 L22.545552,11.5440255 C22.6569791,11.5941677 22.7461882,11.6833768 22.7963304,11.794804 C22.9096495,12.0466241 22.7973722,12.342628 22.545552,12.455947 L8.70518234,18.6841134 C8.64067359,18.7131423 8.57073936,18.7281526 8.5,18.7281526 C8.22385763,18.7281526 8,18.504295 8,18.2281526 L8,13.1668961 Z" fill="#000000" />
                                                    <path d="M4,16 L5,16 C5.55228475,16 6,16.4477153 6,17 C6,17.5522847 5.55228475,18 5,18 L4,18 C3.44771525,18 3,17.5522847 3,17 C3,16.4477153 3.44771525,16 4,16 Z M1,11 L5,11 C5.55228475,11 6,11.4477153 6,12 C6,12.5522847 5.55228475,13 5,13 L1,13 C0.44771525,13 6.76353751e-17,12.5522847 0,12 C-6.76353751e-17,11.4477153 0.44771525,11 1,11 Z M4,6 L5,6 C5.55228475,6 6,6.44771525 6,7 C6,7.55228475 5.55228475,8 5,8 L4,8 C3.44771525,8 3,7.55228475 3,7 C3,6.44771525 3.44771525,6 4,6 Z" fill="#000000" opacity="0.3" />
                                                </g>
                                            </svg>
                                        </span>Send
                                    </a>
                                </div>
                            </div>

                        </div>

                    </div>

                </Fragment>
            </div>
        </Fragment>
    )
}

Contact.propTypes = {
    contactUs: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
}

export default connect(null, { contactUs, setAlert })(Contact)
