import React, { Fragment } from 'react'

const Welcome = ({ type }) => {
    return (
        <Fragment>
            {type === "top" ? (
                <div className="vw-100 bg-black welcome">
                    <div id='stars'></div>
                    <div id='stars2'></div>
                    <div id='stars3'></div>
                </div>
            ) : type === "bottom" && (
                <div className="vw-100 bg-black welcome">
                    <div id='stars'></div>
                    <div id='stars2'></div>
                    <div id='stars3'></div>
                </div>
            )}
        </Fragment>
    )
}

export default Welcome
