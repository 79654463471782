import React, { Fragment } from 'react'
import 'animate.css';
import Spacer from '../layout/Spacer';

const Intro = () => {
    return (
        <Fragment>
            <svg width="100%" id="svg" viewBox="0 0 1440 400" xmlns="http://www.w3.org/2000/svg" className="transition duration-300 ease-in-out delay-150 mt-20"><path d="M 0,400 C 0,400 0,200 0,200 C 52.6182294007243,163.65336117793228 105.2364588014486,127.30672235586457 153,141 C 200.7635411985514,154.69327764413543 243.67239419492984,218.4264717544741 292,237 C 340.32760580507016,255.5735282455259 394.07396441883213,228.98739062623912 441,197 C 487.92603558116787,165.01260937376088 528.0317481297416,127.62396574056942 579,138 C 629.9682518702584,148.37603425943058 691.799043062201,206.51674641148324 739,223 C 786.200956937799,239.48325358851676 818.7720796214544,214.3090486134976 862,217 C 905.2279203785456,219.6909513865024 959.1126384519814,250.24705913452644 1007,235 C 1054.8873615480186,219.75294086547356 1096.7773665706202,158.70271484839674 1147,140 C 1197.2226334293798,121.29728515160328 1255.7778952655372,144.94208147188664 1306,162 C 1356.2221047344628,179.05791852811336 1398.1110523672314,189.52895926405668 1440,200 C 1440,200 1440,400 1440,400 Z" stroke="none" strokeWidth="0" fill="#9072d1ff" className="transition-all duration-300 ease-in-out delay-150 path-0"></path></svg>
            <div className="vw-100 bg-info">
                <div className="container p-20">

                    {/* Location */}
                    <div className="row">
                        <span className="svg-icon svg-icon-white svg-icon-10x p-0">
                            <svg className="animate__animated animate__bounce animate__slow animate__infinite" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <rect x="0" y="0" width="24" height="24" />
                                    <path d="M5,10.5 C5,6 8,3 12.5,3 C17,3 20,6.75 20,10.5 C20,12.8325623 17.8236613,16.03566 13.470984,20.1092932 C12.9154018,20.6292577 12.0585054,20.6508331 11.4774555,20.1594925 C7.15915182,16.5078313 5,13.2880005 5,10.5 Z M12.5,12 C13.8807119,12 15,10.8807119 15,9.5 C15,8.11928813 13.8807119,7 12.5,7 C11.1192881,7 10,8.11928813 10,9.5 C10,10.8807119 11.1192881,12 12.5,12 Z" fill="#000000" fillRule="nonzero" />
                                </g>
                            </svg>
                        </span>
                        <h1 className="display-2 welcome-text text-white front">We are a London based R & D team</h1>
                        <p className="text-white front">
                            We have worked with global multinational corporations and dynamic startups across the world on innovative technology projects
                        </p>
                    </div>
                    <Spacer height="30" />

                    {/* Beliefs */}
                    <div className="row justify-content-right text-right my-30">
                        <span className="svg-icon svg-icon-white svg-icon-10x p-0">
                            <svg className="animate__animated animate__heartBeat animate__slow animate__infinite" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <rect x="0" y="0" width="24" height="24" />
                                    <rect fill="#000000" opacity="0.3" x="2" y="3" width="20" height="18" rx="2" />
                                    <path d="M9.9486833,13.3162278 C9.81256925,13.7245699 9.43043041,14 9,14 L5,14 C4.44771525,14 4,13.5522847 4,13 C4,12.4477153 4.44771525,12 5,12 L8.27924078,12 L10.0513167,6.68377223 C10.367686,5.73466443 11.7274983,5.78688777 11.9701425,6.75746437 L13.8145063,14.1349195 L14.6055728,12.5527864 C14.7749648,12.2140024 15.1212279,12 15.5,12 L19,12 C19.5522847,12 20,12.4477153 20,13 C20,13.5522847 19.5522847,14 19,14 L16.118034,14 L14.3944272,17.4472136 C13.9792313,18.2776054 12.7550291,18.143222 12.5298575,17.2425356 L10.8627389,10.5740611 L9.9486833,13.3162278 Z" fill="#000000" fillRule="nonzero" />
                                    <circle fill="#000000" opacity="0.3" cx="19" cy="6" r="1" />
                                </g>
                            </svg>
                        </span>
                        <h1 className="display-2 welcome-text text-white front">That believes in technology with purpose</h1>
                        <p className="text-white front">
                            We strive to create meaningful solutions that make a real difference in peoples lives across the world
                        </p>
                    </div>
                    <Spacer height="30" />

                    <div className="row mt-30">
                        <span className="svg-icon svg-icon-white svg-icon-10x p-0">
                            <svg className="animate__animated animate__flip animate__slow animate__infinite" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <rect x="0" y="0" width="24" height="24" />
                                    <path d="M3,16 L5,16 C5.55228475,16 6,15.5522847 6,15 C6,14.4477153 5.55228475,14 5,14 L3,14 L3,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L3,10 L3,8 L5,8 C5.55228475,8 6,7.55228475 6,7 C6,6.44771525 5.55228475,6 5,6 L3,6 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,19 C11,19.5522847 10.5522847,20 10,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,16 Z" fill="#000000" opacity="0.3" />
                                    <path d="M16,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,15.2485298 C21,15.7329761 20.8241635,16.200956 20.5051534,16.565539 L17.8762883,19.5699562 C17.6944473,19.7777745 17.378566,19.7988332 17.1707477,19.6169922 C17.1540423,19.602375 17.1383289,19.5866616 17.1237117,19.5699562 L14.4948466,16.565539 C14.1758365,16.200956 14,15.7329761 14,15.2485298 L14,5 C14,3.8954305 14.8954305,3 16,3 Z" fill="#000000" />
                                </g>
                            </svg>
                        </span>
                        <h1 className="display-2 welcome-text text-white front">And tackles complexity with creativity</h1>
                        <p className="text-white front">
                            There is no challenge too small, we enjoy working collaborately and creatively to solve problems with original ideas
                        </p>
                    </div>

                </div>
            </div>
            <svg width="100%" id="svg" viewBox="0 0 1440 400" xmlns="http://www.w3.org/2000/svg" className="transition duration-300 ease-in-out delay-150"><path d="M 0,400 C 0,400 0,200 0,200 C 48.52346083692406,228.9733141240847 97.04692167384812,257.9466282481694 151,233 C 204.95307832615188,208.0533717518306 264.3357741415316,129.18680113140712 308,132 C 351.6642258584684,134.81319886859288 379.6099817600254,219.30616722620215 422,238 C 464.3900182399746,256.69383277379785 521.2242988183668,209.5885299637844 578,198 C 634.7757011816332,186.4114700362156 691.4928229665071,210.33971291866027 733,231 C 774.5071770334929,251.66028708133973 800.8044093156045,269.0526183615745 844,271 C 887.1955906843955,272.9473816384255 947.2895397710749,259.4498136350419 996,246 C 1044.710460228925,232.55018636495808 1082.037431600095,219.14812709825793 1136,228 C 1189.962568399905,236.85187290174207 1260.5607338285442,267.95767797192633 1314,267 C 1367.4392661714558,266.04232202807367 1403.7196330857278,233.02116101403684 1440,200 C 1440,200 1440,400 1440,400 Z" stroke="none" strokeWidth="0" fill="#9072d1ff" className="transition-all duration-300 ease-in-out delay-150 path-0" transform="rotate(-180 720 200)"></path></svg>

        </Fragment>
    )
}

export default Intro
