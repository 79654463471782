import React, { useState } from 'react';
import Sidemenu from './Sidemenu';

const Navbar = () => {
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true);
    const handleClose = () => {

        // Add remove class to animate the exit of the menu
        const menu = document.getElementById("sidemenu")
        menu.classList.add('remove');

        // Delay update of state by 0.25 seconds, the same time the animation plays for
        setTimeout(function () {
            setShow(false);
        }, 250);
    }

    return (
        <nav className="vw-100 navbar py-5 navbar-dark bg-black sticky-top">
            <div className="container">

                {/* Navbar */}
                <a className="navbar-brand btn rounded-pill btn-hover-transparent-white" href="/">
                    <img src={process.env.PUBLIC_URL + '../img/logo/logo_light.png'} height="40" className="d-inline-block align-top my-2" alt="Logo"></img>
                </a>

                {/* Toggle button */}
                <button onClick={handleShow} className="btn btn-icon btn-circle btn-hover-transparent-white btn-icon-white btn-hover-icon-info navbar-toggler border-0" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="svg-icon svg-icon-2x">
                        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <path fillRule="evenodd" clipRule="evenodd" d="M22 11.5C22 12.3284 21.3284 13 20.5 13H3.5C2.6716 13 2 12.3284 2 11.5C2 10.6716 2.6716 10 3.5 10H20.5C21.3284 10 22 10.6716 22 11.5Z" fill="black" />
                                <rect opacity="0.5" x="5" y="17" width="14" height="3" rx="1.5" fill="black" />
                                <rect opacity="0.5" x="8" y="3" width="8" height="3" rx="1.5" fill="black" />
                            </g>
                        </svg>
                    </span>
                </button>

                {show && (
                    <Sidemenu closeHandler={handleClose} />
                )}

            </div>
        </nav>
    )
}

export default Navbar;