import mapLabels from './mapLabels.json'

function createMapArcs() {

    let places = mapLabels.features;
    let num_places = places.length;
    let arcs = [];

    for (let i = 0; i < num_places - 1; i++) {
        let first = places[i];
        let second = places[i + 1];
        let startLat = first.properties.latitude;
        let endLat = second.properties.latitude;
        let startLong = first.properties.longitude;
        let endLong = second.properties.longitude;
        let colour = [['#9072D1', '#ffffff', '#1f70bb', '#31af65', '#FDB324'][Math.round(Math.random() * 4)], ['#9072D1', '#ffffff', '#1f70bb', '#31af65', '#FDB324'][Math.round(Math.random() * 4)]]
        arcs.push({
            startLat: startLat,
            startLng: startLong,
            endLat: endLat,
            endLng: endLong,
            color: colour
        })
    }

    return arcs;
};

export default createMapArcs;