import { CONTACT_US_FORM_SUCCESS, CONTACT_US_FORM_ERROR } from '../actions/types';

const initialState = {
    errors: []
}

function contactReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {

        case CONTACT_US_FORM_SUCCESS:
        default:
            return state

        case CONTACT_US_FORM_ERROR:
            return {
                errors: [...state.errors, payload],
            }

    }
}

export default contactReducer;