import React, { Fragment } from 'react'

const NotFound = () => {
    return (
        <Fragment>
            <div className="container mt-15">
                <h1>Not found</h1>
            </div>
        </Fragment>
    )
}

export default NotFound