/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react'
import Contact from '../contact/Contact'
import Footer from '../layout/Footer'
import Header from '../layout/Header'
import Navbar from '../layout/Navbar'
import Spacer from '../layout/Spacer'
import Intro from './Intro'
import Specialities from './Specialities'

const About = () => {
    return (
        <Fragment>
            <Navbar />

            <Header page="services" />

            {/* Intro */}
            <Intro />
            <Spacer height="30" />

            {/* Specialities */}
            <div className="container mb-10">
                <div className="row text-center d-flex justify-content-center">
                    <h1 className="display-1 welcome-text text-black front">Our expertise</h1>
                </div>
                <div className="row text-center d-flex justify-content-center pb-10">
                    <h3 className="welcome-text text-muted front">We have experience with the entire stack of technology.<br />On top of this, we can offer deep knowledge and expertise within 3 areas in particular:</h3>
                </div>
            </div>
            <Specialities />
            <Spacer height="40" />

            {/* Get in touch */}
            {/* <Contact /> */}

            {/* Footer */}
            <Footer />

        </Fragment>
    )
}

export default About
