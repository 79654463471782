/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react'
import Contact from '../contact/Contact';
import Description from '../description/Description';
import Footer from '../layout/Footer';
import Navbar from '../layout/Navbar';
import Spacer from '../layout/Spacer';
import WorldMap from '../map/WorldMap';
import ProjectHorizontalScroll from '../projects/ProjectHorizontalScroll';
import Reviews from '../reviews/Reviews';
import Welcome from './Welcome';

const Landing = () => {
    return (
        <Fragment>
            <Navbar />

            {/* Welcome */}
            <Welcome type="top" />
            <div className="grid d-flex justify-content-center flex-nowrap">
                <div className="container text-center welcome-message">

                    {/* Main header */}
                    <h1 className='display-1 welcome-text text-white front sm-text-shadow'>
                        Creating
                        <span className="gradient-header"> meaningful </span>
                        solutions<br />with emerging
                        <span className="gradient-header pr-3"> technology</span>
                    </h1>
                </div>
            </div>

            {/* Transition */}
            <div className="vw-100" style={{ 'top': '-6px', 'position': 'relative' }}>
                <div id='stars'></div>
                <div id='stars2'></div>
                <div id='stars3'></div>
                <svg width="100%" height="100%" id="svg" viewBox="0 0 1440 400" xmlns="http://www.w3.org/2000/svg" className="transition duration-300 ease-in-out delay-150"><path d="M 0,400 C 0,400 0,200 0,200 C 50.04066985645933,208.18779243437575 100.08133971291866,216.3755848687515 154,204 C 207.91866028708134,191.6244151312485 265.71531100478467,158.6854529593698 308,174 C 350.28468899521533,189.3145470406302 377.0574162679426,252.88260329376934 413,242 C 448.9425837320574,231.11739670623066 494.0550239234451,145.78413386555286 556,150 C 617.9449760765549,154.21586613444714 696.7224880382774,247.98086124401914 741,256 C 785.2775119617226,264.01913875598086 795.055023923445,186.29242115837056 839,146 C 882.944976076555,105.70757884162944 961.0574162679425,102.84945412249863 1022,126 C 1082.9425837320575,149.15054587750137 1126.7153110047848,198.309762351635 1168,200 C 1209.2846889952152,201.690237648365 1248.0813397129186,155.91149647096142 1293,148 C 1337.9186602870814,140.08850352903858 1388.9593301435407,170.0442517645193 1440,200 C 1440,200 1440,400 1440,400 Z" stroke="none" strokeWidth="0" fill="#2b2b2bff" className="transition-all duration-300 ease-in-out delay-150 path-0" transform="rotate(-180 720 200)"></path></svg>
            </div>

            {/* Description */}
            <Description />
            <Spacer height="40" />

            {/* Project carousel */}
            <ProjectHorizontalScroll />
            <Spacer />
            <Spacer height="40" />

            {/* Customer reviews */}
            <Reviews />
            <Spacer height="40" />

            {/* World map */}
            <WorldMap />
            {/* <MapChart /> */}
            <Spacer height="30" />

            {/* Contact us */}
            {/* <Contact /> */}

            {/* Footer */}
            <Footer />

        </Fragment>
    )
}

export default Landing